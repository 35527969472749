import ProfileContainer from './components/ProfileContainer';
import HeaderSectionContainer from 'components/header-section';
import useCheckMobileDevice from 'helpers/useCheckMobileDevice';
import FooterSection from 'components/footer-section/FooterSection';
import MobileSearchContainer from 'components/MobileSearch/MobileSearchContainer';
import BottomNavigationContainer from 'components/BottomNavigation/BottomNavigationContainer';

function ProfilePage() {
    return (
        <div className="hotel-detail-container">

            {
                !useCheckMobileDevice() ?
                    <HeaderSectionContainer activeNavbar={true}
                        hideSearchOnInitialRender={true} /> :
                    <MobileSearchContainer />
            }

            <ProfileContainer />

            <FooterSection />
            <BottomNavigationContainer />

        </div>
    )
}

export default ProfilePage;