import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'helpers/GenericType';
import { postPropertyReview } from '../../../store/actions/ReviewActions';
import ReviewPostForm from './form';

type Props = {
  bookingId: number;
  setIsSubmitted: (param: boolean) => void
}

const ReviewPost = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const submitReview = (reviewData: any) => {
    setIsLoading(true);
    dispatch(postPropertyReview({ ...reviewData, bookingId: props.bookingId, }))
      .then(() => {
        toast.success("Thank You for sharing your valuable feedback!");
        props.setIsSubmitted(true);
      })
      .catch((err: any) => {
        console.log(err);
        toast.error("Something went wrong. Please try again!");
      }).finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ReviewPostForm
      bookingId={props.bookingId}
      submitReview={submitReview}
      isLoading={isLoading}
    />
  )
}

export default ReviewPost;
