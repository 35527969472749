import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "react-dates";
import moment from "moment";

import "react-dates/initialize";
import "./react_dates_overrides.css";
import "react-dates/lib/css/_datepicker.css";
import { updateSearchParams } from "store/actions";

type Props = {
  activeOption: number;
  setActiveOption: (id: number) => void;
};

function CheckDateCalendar(props: Props) {
  const dispatch = useDispatch();
  const { search }: any = useSelector((state) => state);

  const [focus, setFocus] = useState(null) as any;

  useEffect(() => {
    if (props.activeOption === 2) setFocus("startDate");
    else if (props.activeOption === 3) setFocus("endDate");
    else setFocus(null);
  }, [props.activeOption]);

  let startDate = null;
  let endDate = null;

  if (search.checkInDate) {
    startDate = moment(search.checkInDate, "MM-DD-YYYY");
  }

  if (search.checkOutDate) {
    endDate = moment(search.checkOutDate, "MM-DD-YYYY");
  }

  return (
    <div style={{ marginTop: "16px", position: "absolute", zIndex: -1 }}>
      <DateRangePicker
        endDate={startDate}
        startDate={endDate}
        calendarInfoPosition={"after"}
        daySize={45}
        focusedInput={focus}
        hideKeyboardShortcutsPanel={true}
        startDateId="your_unique_start_date_id"
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          if (startDate) {
            dispatch(
              updateSearchParams({
                checkInDate: moment(startDate).format("MM-DD-YYYY"),
              })
            );
            props.setActiveOption(3);
          }
          if (endDate) {
            dispatch(
              updateSearchParams({
                checkOutDate: moment(endDate).format("MM-DD-YYYY"),
              })
            );
            props.setActiveOption(4);
          }
        }}
        onFocusChange={(focusedInput) => setFocus("startDate")}
      />
    </div>
  );
}

export default CheckDateCalendar;
