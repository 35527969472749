import React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { updateSearchParams } from "store/actions";
import { navigateTo, Navigation } from "helpers/Navigation";

type Props = { data: any };

function NearByLocationList(props: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search }: any = useSelector((state) => state);

  const handleClick = (id: string, name: string) => {
    const queryParams = {
      ...search,
      locationName: name,
    };
    dispatch(updateSearchParams({ ...queryParams, locationId: id }));
    navigateTo(Navigation.hlp, history, { location_id: id }, queryParams, true);
  };

  const list = props.data.nearbyDefaultList;

  return (
    <div className="nearby-container">
      <div className="nearby-list">
        {list.map((location: any, index: number) => (
          <div
            className="nearby-card"
            key={index}
            onClick={() => handleClick(location.id, location.name)}
          >
            <img src={location.image} alt={location.name} />
            <div className="description">
              <span>
                <strong>{location.name}</strong>
              </span>
              <br />
              <span>{location.desc}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default React.memo(NearByLocationList);
