import { useDispatch, useSelector } from "react-redux";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";

import "../styles/guests-field.scss";
import { updateSearchParams } from "store/actions";

function GuestsForm() {
  const dispatch = useDispatch();
  const { search }: any = useSelector((state) => state);

  const handleGuests = (action: string, type: string) => {
    const currentVal = search[type] || 0;

    let maxValue = 16;
    if (type !== "adult") maxValue = 5;
    if (action === "plus" && currentVal < maxValue)
      dispatch(updateSearchParams({ [type]: Number(currentVal) + 1 }));
    if (action === "minus" && currentVal > 0)
      dispatch(updateSearchParams({ [type]: Number(currentVal) - 1 }));
  };

  const guestsType = [
    {
      heading: "Adults",
      description: "Ages 13 or above",
      value: search.adult || 0,
      name: "adult",
    },
    {
      heading: "Children",
      description: "Ages 2-12",
      value: search.children || 0,
      name: "children",
    },
    {
      heading: "Infants",
      description: "Under 2",
      value: search.infant || 0,
      name: "infant",
    },
  ];

  return (
    <div className="guests-type-container noselect" style={{ display: "" }}>
      {guestsType.map((guest, index) => {
        let maxValue = 16;
        if (guest.name !== "adult") maxValue = 5;
        return (
          <div key={index} className="guests-type-row">
            <div>
              <div className="heading">{guest.heading}</div>
              <div className="description">{guest.description}</div>
            </div>

            <div className="guests-type-btn-box">
              <div className="minus">
                <AiOutlineMinusCircle
                  onClick={() => handleGuests("minus", guest.name)}
                  size={30}
                  color={guest.value === 0 ? "#DDDDDD" : "black"}
                />
              </div>
              <div className="count">{guest.value || 0}</div>
              <div className="plus">
                <BsPlusCircle
                  size={27}
                  onClick={() => handleGuests("plus", guest.name)}
                  color={guest.value >= maxValue ? "#DDDDDD" : "black"}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default GuestsForm;
