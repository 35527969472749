type Props = {
  title: string;
  description: string;
};

export function HotelDetailsNotFound(props: Props) {
  return (
    <div className="mt-5 mb-5">
      <h2 className="text-center">
        <strong>{props.title}</strong>
      </h2>
      <p className="m-2 text-center">{props.description}</p>
    </div>
  );
}
