import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Loader } from "shared-components";
import { getProfileData, updateProfile } from "store/actions";
import { AppDispatch } from "helpers/GenericType";
import { navigateTo, Navigation } from "helpers/Navigation";
import Profile from "./Profile";

function ProfileContainer() {
  const history = useHistory();
  const disptach: AppDispatch = useDispatch();
  const { user }: any = useSelector((state) => state);
  const [showLoader, setShowLoader] = useState(false);
  const [profileDetails, setProfileDetails] = useState({}) as any;
  const regexForMobile = /^[0-9\b]+$/;

  const handleChanges = (e: any) => {
    const { name, value } = e.target;
    if(name === 'mobile' && (value ==="" || regexForMobile.test(value))){
      setProfileDetails((prev: any) => ({ ...prev, [name]: value }));
    }
    else if(name !== 'mobile'){
      setProfileDetails((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const updateImage = (file: any) => {
    setProfileDetails((prev: any) => ({ ...prev, file: file }));
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const params = {
      ...profileDetails,
      is_email_verify: 1,
      is_mobile_verified: 1,
    };
    setShowLoader(true);
    disptach(updateProfile(params))
      .then((res: any) => {
        if (res?.user) {
          const user = {
            ...res.user,
            user_id: profileDetails.id,
            image: res.user.image || profileDetails.image,
          };
          setProfileDetails(user);
          localStorage.setItem("userData", JSON.stringify(user));
        }
        let prevUrlWithParam = history.location.search;
        let regex = new RegExp("[?&]prevUrl(=([^&#]*)|&|#|$)", "i"),
          results = regex.exec(prevUrlWithParam) || "/";
        const prevUrl = decodeURIComponent(
          results[2]?.replace(/\+/g, " ") || ""
        );
        history.push(prevUrl);
      })
      .finally(() => setShowLoader(false));
  };

  useEffect(() => {
    const userData = user.user
      ? user.user
      : localStorage.getItem("userDetails");
    if (userData) {
      const params = { id: userData.id };
      setShowLoader(true);
      disptach(getProfileData(params))
        .then((res: any) => {
          const user = res?.user || {};
          setProfileDetails({
            ...user,
            dob: user.dob ? moment(user.dob).local().format("YYYY-MM-DD") : "",
          });
        })
        .finally(() => setShowLoader(false));
    } else {
      navigateTo(Navigation.home, history);
    }
  }, []);

  return (
    <Loader showLoader={showLoader}>
      <Profile
        handleUpdate={handleUpdate}
        profileDetails={profileDetails}
        handleChanges={handleChanges}
        updateImage={updateImage}
      />
    </Loader>
  );
}

export default ProfileContainer;
