import { combineReducers } from "redux";
import { AuthReducer, SearchReducer, HotelReducer, MasterReducer, BookingReducer } from "./reducers";

export default combineReducers({
  search: SearchReducer,
  user: AuthReducer,
  hotel: HotelReducer,
  master: MasterReducer,
  booking: BookingReducer
});
