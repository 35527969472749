import EnquiryForm from "./EnquiryForm";

type Props = {
  showButton: boolean;
  handleReserve?: () => void;
  checkAvailability: () => void;
  reviews?: any;
  showLoader?: boolean;
  checkInDate: string;
  checkOutDate: string;
  handleCheckIndate?: (date: any) => void;
  handleCheckOutdate?: (date: any) => void;
  handleGuests?: (action: string, type: string) => void;
  guests: {
    adult: number;
    children: number;
    infant: number;
    [key: string]: number;
  };
};

function EnquiryFormContainer(props: Props) {
  return (
    <div>
      <EnquiryForm
        showButton={props.showButton}
        handleReserve={props.handleReserve}
        checkAvailability={props.checkAvailability}
        reviews={props.reviews}
        showLoader={props.showLoader}
        checkInDate={props.checkInDate}
        checkOutDate={props.checkOutDate}
        handleCheckIndate={props.handleCheckIndate}
        handleCheckOutdate={props.handleCheckOutdate}
        guests={props.guests}
        handleGuests={props.handleGuests}
      />
    </div>
  );
}

export default EnquiryFormContainer;
