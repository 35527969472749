import React from 'react';
import HeaderSectionContainer from 'components/header-section';
import useCheckMobileDevice from 'helpers/useCheckMobileDevice';
import FooterSection from 'components/footer-section/FooterSection';
import MobileSearchContainer from 'components/MobileSearch/MobileSearchContainer';
import BookingConfirmation from './components/BookingConfirmation';

function BookingConfirmationContainer() {
    return (
        <div className="hotel-detail-container">

            {/* {
                !useCheckMobileDevice() ?
                    <HeaderSectionContainer activeNavbar={true}
                        hideSearchOnInitialRender={true} /> :
                    <MobileSearchContainer />
            } */}

            {/* <BookingConfirmation/> */}

            {/* <FooterSection /> */}

        </div>
    )
}

export default BookingConfirmationContainer;
