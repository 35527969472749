export const setLoadingState = (dispatch:any, action:string, loadingState:boolean) => {
  dispatch({
    type: `${action}_LOADING`,
    payload: loadingState,
  });
};

export const setErrorState = (dispatch:any, action:string, payload:object) => {
  dispatch({
    type: `${action}_LOADING`,
    payload: false,
  });
  dispatch({
    type: `${action}_ERROR`,
    payload: payload,
  });
};

export const setSuccessState = (dispatch:any, action:string, payload:object) => {
  dispatch({
    type: `${action}`,
    payload: payload,
  });
};
