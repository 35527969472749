import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import FooterPage from './FooterPage';
import { AppDispatch } from 'helpers/GenericType';
import { getFooterLinkData } from 'store/actions/MasterActions';

function FooterPageContainer() {

    const { id }: any = useParams();
    const dispatch: AppDispatch = useDispatch();
    const [pageDetails, setPageDetails] = useState({
        image_url: "",
        name: "",
        url: "",
        write_up: ""
    });
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setShowLoader(true);
        dispatch(getFooterLinkData({ id: id }))
            .then((res: any) => setPageDetails(res))
            .catch((err: any) => {
                setPageDetails({
                    image_url: "",
                    name: "",
                    url: "",
                    write_up: ""
                });
            })
            .finally(() => setShowLoader(false));
    }, [id]);

    return (
        <FooterPage pageDetails={pageDetails} showLoader={showLoader} />
    )
}

export default FooterPageContainer;
