import "./style.scss";
import Share from "components/share";

type Props = {
  reviews: any;
  propertyMaster: any;
};

function HotelDetailsHeader(props: Props) {
  const { propertyMaster, reviews } = props;

  const handleScroll = () => {
    var elmnt = document.getElementById("review-section");

    // @ts-ignore
    if (elmnt) {
      elmnt.scrollIntoView(true);
    }
  };

  return (
    <div className="hotel-details-header">
      <h3>
        <strong>{props.propertyMaster.flat_name}</strong>
      </h3>

      <div className="hotel-info">
        <div>
          <span className="star">★</span>
          <span>{reviews.avgRating || 0}</span>
          <span className="reviews" onClick={handleScroll}>
            ({reviews.count || 0} reviews)
          </span>
          <span> · </span>
          <span>
            {propertyMaster.city && propertyMaster.city}
            {propertyMaster.state && `, ${propertyMaster.state}`}
            {propertyMaster.country && `, ${propertyMaster.country}`}
          </span>
        </div>
        <Share
          title={props.propertyMaster.flat_name}
          url={
            typeof window !== "undefined"
              ? `${window.location.origin}${window.location.pathname}`
              : "/"
          }
          image={props.propertyMaster.photo}
          description={
            <span>
              ★{reviews.avgRating || 0} ·{" "}
              {propertyMaster.city && propertyMaster.city}
              <br />
              {props.propertyMaster.sub_property_config_details}
            </span>
          }
          waText={`★${reviews.avgRating || 0} · ${propertyMaster?.city || ""
            }\r\n${props.propertyMaster.sub_property_config_details}`}
        />
      </div>
    </div>
  );
}

export default HotelDetailsHeader;
