import React from 'react';
import styles from './style.module.scss';

type Props = {
  type?: string;
  placeholder: string;
  className?: string;
  name: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pattern?: string;
  defaultValue?: string;
  required?: boolean,
  maxLength?: number;
  min?: string;
  max?: string;
  style?: React.CSSProperties;
  lable?: string,
  disabled?: boolean
}

DateField.defaultProps = {
  required: true,
  disabled: false,
}


export function DateField(props: Props) {
  return (
    <div className={styles.formDatePicker}>
      <div className={styles.formDatePickerLabel}>{props.lable}</div>
      <input
        type={"date"}
        className={`${styles.formDatePickerInput} ${props.className}`}
        name={props.name}
        disabled={props.disabled}
        autoComplete={"off"}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        value={props.value}
        required={props.required}
        onChange={props.onChange}
        pattern={props.pattern}
        maxLength={props.maxLength}
        min={props.min}
        max={props.max}
        style={props.style}
      />
    </div>
  )
}


