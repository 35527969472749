import { actionDispatcher, dotNetActionDispatcher } from 'store/actionDispatcher';
import API from 'api-handler/apiType';
import DotNetAPI from 'api-handler/dotNetApiType';

export const HotelActionType = {
  GET_HOTEL_LIST: 'GET_HOTEL_LIST',
  GET_HOTEL_DETAILS: 'GET_HOTEL_DETAILS',
  GET_AVAILABLITY: 'GET_AVAILABLITY',
}

export const getHotelList = (params: object) => (dispatch: any) => {
  return actionDispatcher(
    params,
    HotelActionType.GET_HOTEL_LIST,
    API.getProperties,
    dispatch,
    'post'
  )
}

export const getHotelDetails = (params: object) => (dispatch: any) => {
  return actionDispatcher(
    params,
    HotelActionType.GET_HOTEL_DETAILS,
    API.getPropertyDetails,
    dispatch,
    'post'
  )
}


export const getAvailablity = (params: object) => (dispatch: any) => {
  return dotNetActionDispatcher(
    params,
    HotelActionType.GET_AVAILABLITY,
    DotNetAPI.getAvailablilty,
    dispatch,
    'post'
  )
}