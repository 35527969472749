import { createRef, useEffect, useState, useRef } from "react";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import Modal from "../../../shared-components/Modal";
import Rating from "../rating";
import ReviewCard from "../reviewCard/reviewCard";
import styles from "./style.module.scss";

type Props = {
  reviews: any[];
  ratings: any[];
  showMore: boolean;
  handleClose: () => void;
  reviewWrapper: any;
  defaultReview?: number;
};

const AllReviews = (props: Props) => {
  const defaultReviewRef = useRef<HTMLDivElement | null>(null);
  const [searchText, setSearchText] = useState("");
  const {
    reviews,
    ratings,
    handleClose,
    showMore,
    reviewWrapper,
    defaultReview,
  } = props;
  const [filteredReviews, setFilteredReviews] = useState(props.reviews);

  const clearSearch = () => {
    setSearchText("");
    setFilteredReviews(reviews);
  };

  const highlightText = (text: string, searchTerms: string[]) => {
    let highlightedText = text;
    for (const term of searchTerms) {
      const regex = new RegExp(`\\b${term}\\b`, "gi");
      highlightedText = highlightedText.replace(
        regex,
        (match) => `<span style="background-color: yellow;">${match}</span>`
      );
    }
    return highlightedText;
  };

  const searchReviews = (e: any) => {
    e.preventDefault();
    if (searchText?.trim()?.length > 0) {
      const searchTextList = searchText?.split(" ") || [];
      const filteredList: any[] = [];
      reviews.forEach((reviewObj) => {
        if (
          reviewObj.review &&
          searchTextList.every((word) =>
            reviewObj.review.toLowerCase().includes(word.toLowerCase())
          )
        ) {
          filteredList.push({
            ...reviewObj,
            review: (
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightText(reviewObj.review, searchTextList),
                }}
              ></span>
            ),
          });
        }
      });
      setFilteredReviews(filteredList);
    } else {
      setFilteredReviews(reviews);
    }
  };

  const scrollToCard = () => {
    const modalBodyEl = document.querySelectorAll(`.${styles.modalBody}`);
    const modalHeaderHeight =
      document.querySelectorAll(`.${styles.modalHeader}`)[0]?.scrollHeight || 0;

    if (modalBodyEl[0]) {
      modalBodyEl[0].scrollTop =
        (defaultReviewRef?.current?.offsetTop || 0) - modalHeaderHeight;
    }
  };

  useEffect(() => {
    if (defaultReview && defaultReviewRef?.current) {
      scrollToCard();
    }
  }, [defaultReview, defaultReviewRef]);

  useEffect(() => {
    setFilteredReviews(reviews);
    setSearchText("");
  }, [reviews]);

  return (
    <Modal
      visible={showMore}
      heading={""}
      handleClose={handleClose}
      containerClass={styles.modalContainer}
      className={styles.modal}
      headerClass={styles.modalHeader}
      bodyClass={styles.modalBody}
    >
      <div className={styles.modalContent}>
        <div className={styles.modalRatingWrapper}>
          {reviewWrapper}
          <Rating ratings={ratings} inOneRow={true} />
        </div>

        <div className={styles.modalReviewCardWrapper}>
          <form className={styles.searchWrapper} onSubmit={searchReviews}>
            <AiOutlineSearch size={18} className={styles.searchIcon} />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Search Reviews"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText.length > 0 && (
              <AiOutlineClose
                size={18}
                className={`modal-close-icon ${styles.clearIcon}`}
                onClick={clearSearch}
              />
            )}
          </form>
          {filteredReviews.map((review, index) => (
            <div
              className={styles.modalReviewCard}
              key={index}
              ref={review.id === defaultReview ? defaultReviewRef : createRef()}
            >
              <ReviewCard
                review={review}
                hideBox={true}
                autoHeight={true}
                handleShowMore={() => { }}
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AllReviews;
