import "./style.scss";
import { RiArrowLeftSLine } from "react-icons/ri";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";

type Props = {
  endDate: any;
  startDate: any;
  activeOption: any;
  handleNext: () => void;
  handleBack: () => void;
  handleClear: () => void;
  setActiveOption: (focus: any) => void;
  handleCheckOut: (endDate: any) => void;
  handleCheckIn: (startDate: any) => void;
};

function MobileCalendar(props: Props) {

  return (
    <div className="mobile-calendar-container">
      <RiArrowLeftSLine
        size={30}
        className="back-icon"
        onClick={props.handleBack}
      />
      <div className="mobile-calendar-clear" onClick={props.handleClear}>
        Clear
      </div>
      <div style={{ marginTop: "-60px", marginLeft: "170px" }}>
        <DateRangePicker
          endDate={props.endDate}
          startDate={props.startDate}
          daySize={40}
          calendarInfoPosition={"after"}
          orientation={"vertical"}
          dayPickerNavigationInlineStyles={{
            marginBottom: "20px",
          }}
          focusedInput={props.activeOption}
          hideKeyboardShortcutsPanel={true}
          startDateId="your_unique_start_date_id"
          endDateId="your_unique_end_date_id"
          onDatesChange={({ startDate, endDate }) => {
            if (startDate) {
              props.handleCheckIn(startDate);
            }
            if (endDate) {
              props.handleCheckOut(endDate);
            }
          }}
          onFocusChange={(focusedInput) => {
            if (focusedInput) props.setActiveOption(focusedInput);
          }}
        />
      </div>

      <div className="bottom-controller">
        <div
          className="bottom-skip"
          onClick={() => {
            props.handleNext();
            props.handleClear();
          }}
        >
          Skip
        </div>
        <div className="bottom-next" onClick={props.handleNext}>
          Next
        </div>
      </div>
    </div>
  );
}

export default MobileCalendar;
