import { FaSpinner } from "react-icons/fa";
import "./style.scss";

type Props = {
  text: string;
  showLoader?: boolean;
  className?: string;
  type: "submit" | "reset" | "button";
  disabled?: boolean;
  onClick?: () => void;
};

export function FormButton(props: Props) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={`form-button ${props.className}`}
      type={props.type || "submit"}
    >
      {props.text}{" "}
      {props.showLoader && <FaSpinner size={20} className="icon spinner" />}
    </button>
  );
}
