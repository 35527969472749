import React, { useState } from 'react';
import './style.scss';
import { BsPlusCircle } from 'react-icons/bs';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { AiOutlineMinusCircle } from 'react-icons/ai';

type Props = {
    guestsType: any[]
    handleNext: () => void
    handleBack: () => void
    handleClear: () => void
    handleGuests: (action: string, type: string) => void,
}

function AddGuests(props: Props) {

    return (
        <div className="m-add-guest-container">
            <RiArrowLeftSLine size={30} className="back-icon" onClick={props.handleBack} />
            <h3 className="guests-clear" onClick={props.handleClear}>Clear</h3>
            <h4 className="guests-heading">Add guests</h4>

            <div className="m-guests-type-container noselect">

                {
                    props.guestsType.map((guest, index) => (
                        <div key={index} className="guests-type-row">

                            <div>
                                <div className="heading">{guest.heading}</div>
                                <div className="description">{guest.description}</div>
                            </div>

                            <div className="guests-type-btn-box">
                                <div className="minus"><AiOutlineMinusCircle onClick={() => props.handleGuests('minus', guest.name)} size={30} color={guest.value === 0 ? "#DDDDDD" : "black"} /></div>
                                <div className="count">{guest.value}</div>
                                <div className="plus"><BsPlusCircle size={27} onClick={() => props.handleGuests('plus', guest.name)} color={guest.value >= 20 ? "#DDDDDD" : "black"} /></div>
                            </div>

                        </div>
                    ))
                }

            </div>

            <div className="bottom-controller">
                <div className="bottom-skip" onClick={() => {
                    props.handleClear();
                    props.handleNext();
                }}>Skip</div>
                <div className="bottom-next" onClick={props.handleNext}>Next</div>
            </div>
        </div>
    )
}

export default AddGuests;




