import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import './styles/search.scss';
import { Notification } from 'shared-components';
import { AppDispatch } from 'helpers/GenericType';
import GuestsField from './components/GuestsField';
import CheckInDate from './components/CheckInDate';
import CheckOutDate from './components/CheckOutDate';
import LocationField from './components/LocationField';
import { Navigation, navigateTo } from 'helpers/Navigation';
import { getHotelList, updateSearchParams } from 'store/actions';
import CheckDateCalendar from './components/CheckDateCalendar';
import { getQueryParamsFromUrl } from 'utilities/HelperFunctions';
import { IoMdClose, IoIosSearch } from 'react-icons/io';

function Search() {

    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();
    const { locationId }: any = useParams();
    const { search }: any = useSelector(state => state);
    const [activeOption, setActiveOption] = useState(0);
    const [removeListener, setListener] = useState(true);

    useEffect(() => {
        const queryParams = getQueryParamsFromUrl();
        if (queryParams) {
            const params = {
                locationId: locationId || "",
                checkInDate: queryParams.checkInDate || "",
                checkOutDate: queryParams.checkOutDate || "",
                adult: queryParams.adult || 0,
                children: queryParams.children || 0,
                infant: queryParams.infant || 0,
            }
            dispatch(updateSearchParams(params))
        } else {
            if (locationId) dispatch(getHotelList({ location_id: locationId }))
        }
    }, []);

    const handleActiveOption = (id: number) => setActiveOption(id);

    const validateFields = () => {
        if (search.locationId === "") {
            Notification('Search', 'Please select destination from list', 'warning');
            return false;
        } else if (search.checkInDate !== "" || search.checkOutDate !== "") {
            //alert('check in date');
            const checkDate = new Date(search.checkInDate).valueOf();
            const checkOutDate = new Date(search.checkOutDate).valueOf();
            if (checkDate > checkOutDate) {
                Notification('Search', 'Please select valid dates', 'warning');
                return false;
            }
        }
        return true;
    }

    const handleSearch = () => {
        if (validateFields()) {
            const params = {
                location_id: search.locationId,
                check_in: search.checkInDate,
                check_out: search.checkOutDate,
                of_adults: search.adult,
                of_children: search.children,
                of_infants: search.infant
            }
            setListener(false);
            setActiveOption(0);
            dispatch(getHotelList(params)).then((res: any) => {
                if (res.propertyData) navigateTo(Navigation.hlp, history, { id: search.locationId }, search);
                else Notification('Search Result', 'No Result Found', 'warning');
            })
        }
    }

    window.addEventListener('click', function (e: any) {
        if (removeListener) {
            if (document.getElementById('add-location')?.contains(e.target)) setActiveOption(1);
            else if (document.getElementById('add-check-in')?.contains(e.target)) setActiveOption(2);
            else if (document.getElementById('add-check-out')?.contains(e.target)) setActiveOption(3);
            else if (document.getElementById('add-guests')?.contains(e.target)) setActiveOption(4);
            else setActiveOption(0);
        }
    });

    return (
        <>
        <div className="search-container">
            <div id="search-style" className={`search-style ${activeOption !== 0 && 'disabled'}`}>

                <div id="add-location" className="d-flex">
                    <LocationField activeOption={activeOption} setActiveOption={handleActiveOption} />
                    <div className={`${![1, 2].includes(activeOption) && "seprator"}`} />
                </div>

                <div id="add-check-in" className="d-flex">
                    <CheckInDate activeOption={activeOption} />
                    <div className={`${![2].includes(activeOption) && "seprator"}`} />
                </div>

                <div id="add-check-out" className="d-flex">
                    <CheckOutDate activeOption={activeOption} />
                    <div className={`${![3, 4].includes(activeOption) && "seprator"}`} />
                </div>

                <div id="add-guests" className="d-flex">
                    <GuestsField activeOption={activeOption} handleSearch={handleSearch} />
                </div>

            </div>

            <CheckDateCalendar activeOption={activeOption} setActiveOption={handleActiveOption} />

            <div className={`search-btn search-custom`} onClick={handleSearch}>
                <div className="d-flex">
                    <IoIosSearch className="search-icon" color="white" size={25} />
                    {
                    (activeOption !== 0) &&
                        <span className="search-text">Search</span>
                    }
                </div>
            </div>

        </div>
        
        </>
    )
}

export default Search;
