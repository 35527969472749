import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FcDownload } from "react-icons/fc";
import { Loader } from "shared-components";
import BookingReceipt from "components/booking-receipt";
import CancelBooking from "./CancelBooking";
import styles from "../styles/booking-details.module.scss";

type Props = {
	bookingDetails: any;
	subPropertyList: any;
	showLoader: boolean;
};

function BookingDetails(props: Props) {
	console.log("Property List:", props.subPropertyList);
	const { user }: any = useSelector((state) => state);
	const { bookingDetails, subPropertyList, showLoader } = props;
	const [element, setElement] = useState(null) as any;

	useEffect(() => {
		const invoice = document.getElementById("invoice");

		if (bookingDetails.booking_id) {
			setElement(invoice);
		}
	}, [bookingDetails]);

	const onDownloadInvoice = () => {
		// @ts-ignore
		window.html2pdf().from(element).save("Invoice");
	};

	return (
		<div className={`container ${styles.bookingDetailsContainer}`}>
			<Loader showLoader={showLoader}>
				<div className={styles.headingWrapper}>
					<h4 className={styles.heading}>Your receipt from BlaBlaStay</h4>
					<span onClick={onDownloadInvoice} className={styles.downloadBtn}>
						<FcDownload /> Download Invoice
					</span>
				</div>
				<BookingReceipt bookingDetails={bookingDetails} user={user} />
			</Loader>

			<div className={styles.detailsContainer}>
				<hr />
				<h1 className={styles.bookingDetailsHeading}>Hotel Details</h1>
				<div className="row">
					<div className={`${styles.columnWrapper} col-md-4 col-sm-6`}>
						<div className={styles.detailsHeading}>Hotel Name</div>
						<div className={styles.detailsValue}>
							{bookingDetails.propertyName}
						</div>
					</div>
					<div className={`${styles.columnWrapper} col-md-4 col-sm-6`}>
						<div className={styles.detailsHeading}>Hotel Address</div>
						<div className={styles.detailsValue}>{bookingDetails.address}</div>
					</div>
					<div className={`${styles.columnWrapper} col-md-4 col-sm-6`}>
						<div className={styles.detailsHeading}>Host Name</div>
						<div className={styles.detailsValue}>
							{bookingDetails.host_name}
						</div>
					</div>
					<div className={`${styles.columnWrapper} col-md-4 col-sm-6`}>
						<div className={styles.detailsHeading}>Hotel Mobile</div>
						<div className={styles.detailsValue}>
							{bookingDetails.host_mobile}
						</div>
					</div>
					<div className={`${styles.columnWrapper} col-md-4 col-sm-6`}>
						<div className={styles.detailsHeading}>Contact Person</div>
						<div className={styles.detailsValue}>
							{bookingDetails.contact_preson}
						</div>
					</div>
					<div className={`${styles.columnWrapper} col-md-4 col-sm-6`}>
						<div className={styles.detailsHeading}>Contact Person Mobile</div>
						<div className={styles.detailsValue}>
							{bookingDetails.contact_preson_mobile}
						</div>
					</div>
				</div>
			</div>
			{subPropertyList?.length > 0 && (
				<>
					<hr />

					<h1 className={styles.bookingDetailsHeading}>Sub Property Details</h1>
					<div>
						{subPropertyList.map((property: any) => {
							return (
								<div className="row">
									<div className={`${styles.columnWrapper} col-lg-3 col-md-6`}>
										<div className={styles.detailsHeading}>Flat Name</div>
										<div className={styles.detailsValue}>
											{property.flat_name}
										</div>
									</div>
									<div className={`${styles.columnWrapper} col-lg-3 col-md-6`}>
										<div className={styles.detailsHeading}>Capacity</div>
										<div className={styles.detailsValue}>
											{property.capacity}
										</div>
									</div>
									<div className={`${styles.columnWrapper} col-lg-3 col-md-6`}>
										<div className={styles.detailsHeading}>Host Name</div>
										<div className={styles.detailsValue}>
											{property.hostname}
										</div>
									</div>
									<div className={`${styles.columnWrapper} col-lg-3 col-md-6`}>
										<div className={styles.detailsHeading}>Host Email</div>
										<div className={styles.detailsValue}>
											{property.hostemail}
										</div>
									</div>
									<div className={`${styles.columnWrapper} col-lg-3 col-md-6`}>
										<div className={styles.detailsHeading}>Host Mobile</div>
										<div className={styles.detailsValue}>
											{property.hostmobile}
										</div>
									</div>
									<div className={`${styles.columnWrapper} col-lg-3 col-md-6`}>
										<div className={styles.detailsHeading}>Manager Mobile</div>
										<div className={styles.detailsValue}>
											{property.manager_mobile}
										</div>
									</div>
									<div className={`${styles.columnWrapper} col-lg-3 col-md-6`}>
										<div className={styles.detailsHeading}>Maxmimum Guests</div>
										<div className={styles.detailsValue}>
											{property.max_guest}
										</div>
									</div>
									<div className={`${styles.columnWrapper} col-lg-3 col-md-6`}>
										<div className={styles.detailsHeading}>Property Type</div>
										<div className={styles.detailsValue}>
											{property.sub_property_type}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</>
			)}
			<CancelBooking bookingDetails={props.bookingDetails} />
		</div>
	);
}

export default BookingDetails;
