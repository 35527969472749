import ImageGallery from "react-image-gallery";

import "./style.scss";
import Images from "utilities/Images";
import ImageGridDisplay from "./ImageGridDisplay";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

type Props = {
  isMobile: boolean;
  images: any[];
};

function ImageGalleryContainer(props: Props) {
  const renderLeftNav = (onClick: any, disabled: boolean) => (
    <button
      className="image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}
    >
      <FaChevronCircleLeft size={30} color="white" />
    </button>
  );

  const renderRightNav = (onClick: any, disabled: boolean) => (
    <button
      className="image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}
    >
      <FaChevronCircleRight size={30} color="white" />
    </button>
  );

  let tempImages = props.images.map((image) => ({
    original: image.photo,
    thumbnail: image.photo,
  }));

  if (tempImages.length === 0)
    tempImages = [
      {
        original: Images.noImage,
        thumbnail: Images.noImage,
      },
    ];

  return (
    <>
      {props.isMobile ? (
        <div className="image-gallery">
          <ImageGallery
            items={tempImages}
            showIndex={true}
            showPlayButton={false}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
          />
        </div>
      ) : (
        <ImageGridDisplay images={props.images} />
      )}
    </>
  );
}

export default ImageGalleryContainer;
