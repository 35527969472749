import React from 'react';
import '../styles/payment-details.scss';
import { FormButton } from 'shared-components';
import { useHistory } from 'react-router';
import { navigateTo, Navigation } from 'helpers/Navigation';
import Images from 'utilities/Images';

type Props = {
    paymentDetails: any
}

function PaymentDetails(props: Props) {
    console.log("Payment Details:", props.paymentDetails);

    const history = useHistory();
    const bookingData = props.paymentDetails?.booking_data || {}
    const property = props.paymentDetails?.booking_property || {};
    const subProperty = props.paymentDetails?.booking_sub_property || [];
    const userData = props.paymentDetails?.user_data || {};

    const handleBookings = () => navigateTo(Navigation.myBookings, history);

    return (
        <div className="payment-details-container">
            <div className="m-4 row justify-space-between">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div style={{padding: '50px'}}>
                    <h1 style={{ color: "green", fontSize: "20px" }}>Your booking done successfully!!!</h1>
                    <p style={{ color: "black", fontSize: "15px", fontWeight: 'bold' }}>You can view your bookings on my booking page, click button below to see.</p>
                    <div style={{width : '200px'}}>
                    <FormButton text="My Bookings" onClick={() => handleBookings()} type="button" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <img src={Images.success} className="image-style"/>
                </div>
            </div>
        </div>
    )
}

export default PaymentDetails;
