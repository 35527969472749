import { useEffect, useState } from "react";
import moment from "moment";
import Images from "utilities/Images";
import { formatAmount, getCurrencySymbol } from "utilities/HelperFunctions";
import { useHistory } from "react-router";
import { navigateTo, Navigation } from "helpers/Navigation";
import { Loader } from "shared-components";
import BookingReview from "./BookingReview";
import styles from "../styles/my-booking.module.scss";

const tabList = [
  {
    id: 1,
    title: "Upcoming",
    status: "upcoming",
  },
  {
    id: 2,
    title: "Ongoing",
    status: "ongoing",
  },
  {
    id: 3,
    title: "Completed",
    status: "completed",
  },
  {
    id: 4,
    title: "Cancelled",
    status: "cancelled",
  },
];

type Props = {
  bookingList: any[];
  getBookingList: (param: string) => void;
  showLoader: boolean;
};

function MyBookings(props: Props) {
  const [activeTab, setActiveTab] = useState("upcoming");

  const history = useHistory();

  const handleClick = (id: string) => {
    navigateTo(Navigation.bookingDetails, history, { bookingId: id });
  };

  useEffect(() => {
    if (activeTab) {
      props.getBookingList(activeTab);
    }
  }, [activeTab]);

  return (
    <div className={`container ${styles.myBookingContainer}`}>
      <div className={styles.heading}>My Bookings</div>
      <div className={styles.tabListWrapper}>
        <div className={styles.tabWrapper}>
          {tabList.map((tab: any) => (
            <div
              key={tab.id}
              className={`${styles.tab} ${activeTab === tab.status ? styles.active : ""
                }`}
            >
              <div
                className={styles.tabName}
                onClick={() => setActiveTab(tab.status)}
              >
                {tab.title} Bookings
              </div>
              <div
                className={activeTab === tab.status ? styles.tabBorder : ""}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <Loader showLoader={props.showLoader}>
        <div className={styles.bookingList}>
          {props.bookingList.map((booking: any, index: number) => (
            <div key={index} className={styles.bookingListItem}>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Booking Id</span>
                <span
                  className={styles.bookingId}
                  onClick={() => handleClick(booking.id)}
                >
                  {booking.booking_id}
                </span>
              </div>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Dates</span>
                <span>
                  {moment(booking.start_date).format("DD MMM YY")} -{" "}
                  {moment(booking.end_date).format("DD MMM YY")}
                </span>
              </div>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Guest</span>
                <span>{booking.guest_name}</span>
              </div>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Price</span>
                <span>
                  {getCurrencySymbol(booking.price_currency)}
                  {formatAmount(booking.price)}
                </span>
              </div>
              <div className={styles.bookingItem}>
                <span className={styles.bookingLabel}>Status</span>
                <span>
                  <strong
                    style={{
                      color: activeTab === "completed" ? "#f0ba0a" : "green",
                    }}
                  >
                    {booking.status_name}
                  </strong>
                </span>
              </div>
              {activeTab === "completed" && (
                <BookingReview bookingId={Number(booking.id)} />
              )}
            </div>
          ))}
          {props.bookingList.length === 0 && (
            <img
              src={Images.booking}
              className={styles.bookingImageStyle}
              alt=""
            />
          )}
        </div>
      </Loader>
    </div>
  );
}

export default MyBookings;
