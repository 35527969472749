import '../styles/search-field.scss';
import { useSelector } from 'react-redux';
import { IoIosSearch } from 'react-icons/io';

type Props = {
  toggleShowSearch: (params: boolean) => void
}

function SearchField(props: Props) {

  const { search }: any = useSelector(state => state);

  return (
    <div className="search-field-container" onClick={() => props.toggleShowSearch(true)}>
      <div className="text">
        {
          (search.locationName === "") ?
            'Start your search' : search.locationName
        }
      </div>
      <div className="search-btn">
        <div className="d-flex">
          <IoIosSearch className="search-icon" color="white" size={20} />
        </div>
      </div>
    </div>
  )
}

export default SearchField;
