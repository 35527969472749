import { useState } from "react";
import moment from "moment";
import { FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { DateField, FormButton, Notification } from "shared-components";
import "./style.scss";
import BillInformation from "./BillInformation";
import EnquiryGuestsField from "./EnquiryGuestsField";

type Props = {
  showButton: boolean;
  handleReserve?: () => void;
  checkAvailability: () => void;
  reviews: any;
  showLoader?: boolean;
  checkInDate: string;
  checkOutDate: string;
  handleCheckIndate?: (date: any) => void;
  handleCheckOutdate?: (date: any) => void;
  handleGuests?: (action: string, type: string) => void;
  guests: {
    adult: number;
    children: number;
    infant: number;
    [key: string]: number;
  }
};

function EnquiryForm(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const { hotel }: any = useSelector((state) => state);

  const { checkInDate, checkOutDate, handleCheckIndate, handleCheckOutdate, guests, handleGuests } = props;

  const validateFields = () => {
    if (checkInDate === "" || checkOutDate === "") {
      Notification("Search", "Please select dates", "warning");
      return false;
    }
    if (checkInDate !== "" || checkOutDate !== "") {
      const checkDate = new Date(checkInDate).valueOf();
      const checkoutDate = new Date(checkOutDate).valueOf();
      if (checkDate > checkoutDate) {
        Notification("Search", "Please select valid dates", "warning");
        return false;
      }
    }
    if (Number(guests.adult) === 0) {
      Notification("Search", "Please select atleast one adult", "warning");
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    if (validateFields()) {
      props.checkAvailability();
    }
  };

  const handleReserve = () => {
    if (props.handleReserve && validateFields()) {
      props.checkAvailability();
      props.handleReserve();
    }
  };

  const availableFlat = hotel.hotelDetails.availableFlat || {};
  const reviews = props.reviews || {};
  const maxGuest =
    hotel.hotelDetails.prop_master != null
      ? hotel.hotelDetails.prop_master.max_guest
      : 0;
  const capacity =
    hotel.hotelDetails.prop_master != null
      ? hotel.hotelDetails.prop_master.capacity
      : 0;

  return (
    <div className="enquiry-form-container">
      <div className="hotel-item-rating-price">
        <div className="hotel-item-price">
          ₹{availableFlat?.dailyPrice}{" "}
          <span className="per-night">/ night</span>
        </div>
        <div className="hotel-item-rating">
          <span className="star">★</span>
          <span className="rating">{reviews?.avgRating || 0}</span>
          <span className="users">
            (
            <FaUserAlt size={10} style={{ margin: "0px 2px", color: "gray" }} />
            {reviews?.count || 0})
          </span>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className={`col-6 pr-2`}>
            <DateField
              lable="Check In"
              disabled={!props.showButton}
              value={checkInDate}
              placeholder="Select Date"
              name="checkIn"
              onChange={(e) => {
                if (handleCheckIndate) handleCheckIndate(e.target.value);
              }}
              min={moment().format("YYYY-MM-DD")}
            />
          </div>
          <div className={`col-6 pl-2`}>
            <DateField
              lable="Check Out"
              disabled={!props.showButton}
              value={checkOutDate}
              placeholder="Select Date"
              name="checkOut"
              onChange={(e) => {
                if (handleCheckOutdate) handleCheckOutdate(e.target.value);
              }}
              min={moment(checkInDate).add(1, 'd').format("YYYY-MM-DD")}
            />
          </div>
        </div>
        <EnquiryGuestsField
          showButton={props.showButton}
          showModal={showModal}
          setShowModal={setShowModal}
          maxGuest={maxGuest}
          capacity={capacity}
          guests={guests}
          handleGuests={handleGuests}
        />
        {props.showButton && (
          <FormButton
            className="mt-4"
            text="Check availability"
            type="submit"
            showLoader={props.showLoader}
          />
        )}
        <BillInformation
          showButton={props.showButton}
          handleReserve={handleReserve}
        />
      </form>
    </div>
  );
}

EnquiryForm.defaultProps = {
  showButton: true,
};

export default EnquiryForm;
