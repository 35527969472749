import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "helpers/GenericType";
import { getProfileData } from "store/actions";
import BookingConfirmation from "./BookingConfirmation";

type Props = {
  handleBack: () => void;
  handleConfirm: (params: any) => void;
  checkInDate: string;
  checkOutDate: string;
  guests: {
    adult: number;
    children: number;
    infant: number;
    [key: string]: number;
  };
  reviews?: any
};

function BookingConfirmationContainer(props: Props) {
  const disptach: AppDispatch = useDispatch();
  const { user }: any = useSelector((state) => state);
  const [profileDetails, setProfileDetails] = useState({}) as any;

  useEffect(() => {
    const userData = user.user
      ? user.user
      : localStorage.getItem("userDetails");
    if (userData) {
      const params = { id: userData.id };
      disptach(getProfileData(params)).then((res: any) =>
        setProfileDetails(res?.user || {})
      );
    }
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div>
      <BookingConfirmation
        profileDetails={profileDetails}
        handleConfirm={props.handleConfirm}
        handleBack={props.handleBack}
        checkInDate={props.checkInDate}
        checkOutDate={props.checkOutDate}
        guests={props.guests}
        reviews={props.reviews}
      />
    </div>
  );
}

export default BookingConfirmationContainer;
