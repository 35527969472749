import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import moment from "moment";
// import logo from "../../../assets/minilogo.png";
import logo from "../../../assets/betaMiniLogo.png";

import HotelDetails from "./HotelDetails";
import { calenderUpdate, reserveBooking } from "store/actions";
import { AppDispatch } from "helpers/GenericType";
import { changeActiveOption } from "store/actions";
import { HotelDetailsNotFound } from "shared-components";
import { navigateTo, Navigation } from "helpers/Navigation";
import BookingConfirmationContainer from "components/booking-confirmation/components/BookingConfirmationContainer";

type Props = {
  showLoader?: boolean;
  isMobile: boolean;
  hotelDetails: any;
  loadHotelDetails: (id: string, flatId: any) => void;
  checkAvailablity: (id: string, flatId: any) => void;
};

type GuestObj = {
  adult: number;
  children: number;
  infant: number;
  [key: string]: number;
};

function HotelDetailsContainer(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const { search, user }: any = useSelector((state) => state);
  const [flatId, setFlatId] = useState("");
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [guests, setGuests] = useState<GuestObj>({
    adult: 0,
    children: 0,
    infant: 0,
  });
  const [reviewData, setReviewData] = useState({
    count: 0,
    avgRating: 0,
  });

  const propDtls = props.hotelDetails;
  const availabileFlat = propDtls.availableFlat || {};
  const propertyMaster = propDtls.prop_master || {};

  const getAndSetReviews = (count = 0, avgRating = 0) => {
    setReviewData({ count, avgRating });
  };

  const handleGuests = (action: string, type: string) => {
    const currentVal = guests[type] || 0;

    const maxGuest = Number(propertyMaster.max_guest) || 0;
    const capacity = Number(propertyMaster.capacity) || 0;
    let maxValue = capacity > maxGuest ? capacity : maxGuest;

    if (type === "infant") {
      maxValue = 2; // keep it static
      if (action === "plus" && currentVal < maxValue) {
        setGuests((prev) => ({ ...prev, [type]: Number(currentVal) + 1 }));
      }
    } else {
      if (action === "plus" && guests.adult + guests.children < maxValue) {
        setGuests((prev) => ({ ...prev, [type]: Number(currentVal) + 1 }));
      }
    }
    if (action === "minus" && currentVal > 0) {
      setGuests((prev) => ({ ...prev, [type]: Number(currentVal) - 1 }));
    }
  };

  const handleCheckIndate = (date: string) => {
    const parsedInDate = moment(date, "YYYY-MM-DD", true);
    setCheckInDate(
      parsedInDate.isValid() ? parsedInDate.format("YYYY-MM-DD") : ""
    );
  };

  const handleCheckOutdate = (date: string) => {
    const parsedOutDate = moment(date, "YYYY-MM-DD", true);
    setCheckOutDate(
      parsedOutDate.isValid() ? parsedOutDate.format("YYYY-MM-DD") : ""
    );
  };

  const handleIndividualProperty = (item: any) => {
    if (item.spt_id != flatId) setFlatId(item.spt_id);
    else setFlatId("");
    props.loadHotelDetails(item.propid, item.flat_id);
  };

  const handleGroupProperty = (item: any) => {
    if (item.flat_id != flatId) setFlatId(item.flat_id);
    else setFlatId("");
    props.loadHotelDetails(item.propid, item.sub_property_type_id);
  };

  const handleAvailability = () => {
    props.checkAvailablity(propertyMaster.propid, flatId);
  };

  const handleReserve = () => {
    if (user.user) setShowBookingDetails(true);
    else dispatch(changeActiveOption(1));
  };

  const handleConfirm = (guestsData: any) => {
    const flatIds = [propertyMaster?.flat_id] || [];
    const params = {
      ...guestsData,
      user_id: user.user.id,
      check_in: checkInDate,
      check_out: checkOutDate,
      of_adults: guests.adult,
      of_children: guests.children,
      of_infants: guests.infant,
      booking_data: flatIds.map((flat_id: any) => ({
        flat_id: flat_id,
        extra_bedding: 0,
      })),
      billing_data: {
        daily_price: availabileFlat.dailyPrice,
        base_price: availabileFlat.basePrice,
        of_adults: availabileFlat.ofAdults,
        of_children: availabileFlat.ofChildren,
        of_infants: availabileFlat.ofInfants,
        check_in: checkInDate,
        check_out: checkOutDate,
        nights: availabileFlat.nights,
        rooms_required: availabileFlat.roomsRequired,
        service_charges: availabileFlat.serviceCharges,
        internet: availabileFlat.internet,
        total_extra_price: availabileFlat.totalExtraPrice,
        extra_bedding_price: availabileFlat.extraBeddingPrice,
        total_price: availabileFlat.totalPrice,
      },
      booking_type: "daily_price",
    };
    dispatch(reserveBooking(params)).then((res: any) => {
      // API 
      if(res?.responseData?.id != null)
      {
        dispatch(calenderUpdate({flatId: parseInt(propertyMaster?.flat_id), bookingId: res.responseData.id}));
      }

      openRazorPay({
        ...guestsData,
        id: res.responseData.id,
        bookingId: res.responseData.order_data.id,
        amount: availabileFlat.totalPrice || 0,
      });
    });
  };

  const handlePayment = (paymentDetails: any, id: string) => {
    navigateTo(
      Navigation.paymentDetails,
      history,
      {
        bookingId: id,
      },
      {
        paymentId: paymentDetails.razorpay_payment_id,
      }
    );
  };

  const openRazorPay = (bookingDetails: any) => {
    let options = {
      // "key": "rzp_test_k8ukQ1g5qrYREh",
      // "keyId": "rzp_test_k8ukQ1g5qrYREh",
      key: "rzp_live_ros3vJxfaWEkte",
      keyId: "rzp_live_ros3vJxfaWEkte",
      id: bookingDetails.bookingId,
      order_id: bookingDetails.bookingId,
      amount: bookingDetails.amount,
      name: "BlaBlaStay",
      image: logo,
      description: "Proceed Payement",
      handler: (details: any) => handlePayment(details, bookingDetails.id),
      prefill: {
        name: bookingDetails.guest_name,
        email: bookingDetails.guests_email,
        contact: bookingDetails.guests_mobile,
      },
      modal: {
        ondismiss: () => console.log("fialed"),
      },
    };

    // @ts-ignore
    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  const updateQueryParams = (params: any) => {
    const searchParams = new URLSearchParams(location.search);

    for (const key in params) {
      searchParams.set(key, params[key]);
    }

    history.push({ search: searchParams.toString() });
  };

  useEffect(() => {
    if (propertyMaster.flat_id) {
      setFlatId(propertyMaster.flat_id);
    }
  }, [propertyMaster.flat_id]);

  useEffect(() => {
    var dateArray = search.checkInDate.split("-");
    var newDateCheckIn = dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1];
    setCheckInDate(newDateCheckIn);

    const date1: any = new Date(search.checkInDate);
    const date2: any = new Date(search.checkOutDate);

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 0) {
      setCheckOutDate(
        moment(search.checkInDate).add(1, "days").format("YYYY-MM-DD")
      );
    } else {
      var dateArray1 = search.checkOutDate.split("-");
      var newDateCheckOut =
        dateArray1[2] + "-" + dateArray1[0] + "-" + dateArray1[1];
      setCheckOutDate(newDateCheckOut);
    }
  }, [search.checkInDate, search.checkOutDate]);

  useEffect(() => {
    setGuests({
      adult: Number(search.adult) || 0,
      children: Number(search.children) || 0,
      infant: Number(search.infant) || 0,
    });
  }, [search.adult, search.children, search.infant]);

  useEffect(() => {
    const parsedInDate = moment(checkInDate, "YYYY-MM-DD", true);
    const parsedOutDate = moment(checkOutDate, "YYYY-MM-DD", true);
    updateQueryParams({
      checkInDate: parsedInDate.isValid()
        ? parsedInDate.format("MM-DD-YYYY")
        : "",
      checkOutDate: parsedOutDate.isValid()
        ? parsedOutDate.format("MM-DD-YYYY")
        : "",
      ...guests,
    });
  }, [checkInDate, checkOutDate, guests]);

  return (
    <div className="hotel-details-style">
      {showBookingDetails ? (
        <BookingConfirmationContainer
          handleConfirm={handleConfirm}
          handleBack={() => setShowBookingDetails(false)}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          guests={guests}
          reviews={reviewData}
        />
      ) : !Object.keys(propDtls).length ? (
        <HotelDetailsNotFound
          title="Hotel Details not found."
          description="No Hotels found for this location"
        />
      ) : (
        <HotelDetails
          flatId={flatId}
          isMobile={props.isMobile}
          hotelDetails={props.hotelDetails}
          handleReserve={handleReserve}
          handleAvailability={handleAvailability}
          handleGroupProperty={handleIndividualProperty}
          handleIndividualProperty={handleGroupProperty}
          showLoader={props.showLoader}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          handleCheckIndate={handleCheckIndate}
          handleCheckOutdate={handleCheckOutdate}
          guests={guests}
          handleGuests={handleGuests}
          reviews={reviewData}
          getAndSetReviews={getAndSetReviews}
        />
      )}
    </div>
  );
}

export default HotelDetailsContainer;
