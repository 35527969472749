import "./style.scss";

import { Loader } from "shared-components";
import HostFeatures from "./components/HostFeatures";
import PropertyList from "./components/PropertyList";
import TextAnimation from "./components/TextAnimation";
import FutureGateways from "./components/FutureGateways";
import NearbyLocations from "./components/NearbyLocations";
import HeaderSectionContainer from "components/header-section";
import FooterSection from "components/footer-section/FooterSection";
import BottomNavigationContainer from "components/BottomNavigation/BottomNavigationContainer";

type Props = {
  isMobile: boolean;
  homePageData: any;
  showLoader: boolean;
};

function HomePage(props: Props) {
  return (
    <>
      <HeaderSectionContainer
        activeNavbar={false}
        hideSearchOnInitialRender={false}
      />

      <div className="home-page-banner">
        <div className="overlay">
          <TextAnimation />
        </div>
      </div>

      <Loader showLoader={props.showLoader}>
        <>
          <NearbyLocations data={props.homePageData} />
          <PropertyList data={props.homePageData} />
          {/* <HostFeatures data={props.homePageData} /> */}
          {/* <FutureGateways data={props.homePageData} /> */}
          <FooterSection />
        </>
      </Loader>

      <BottomNavigationContainer />
    </>
  );
}

export default HomePage;
