const DotNetAPI = Object.freeze({
  //Properties
  getAvailablilty: "/api/Property/available-property",
  //Bookings
  getBookingDetails: "/api/Property/bookingbyid/",
  getSubPropertyList: "/api/Property/subpropertybyid/",
  calenderUpdate: "/api/Booking/saveforcalenderview",
  reserveBooking: "/api/booking/createbookingfromguestportal",
  //Login OTP
  sendLoginOTP: "/api/User/sendotp",
});

export default DotNetAPI;
