import React, { useState } from "react";
import { BsGrid3X3Gap } from "react-icons/bs";
import ImageDisplaySection from "./ImageDisplaySection";

type Props = {
  images: any[];
};

function ImageGridDisplay(props: Props) {
  const [id, setId] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const images = [...props.images];
  // @ts-ignore
  images.length = 5;

  const handleImageClick = (id: number) => {
    setId(id);
    setShowModal(true);
  };

  const handleId = (id: number) => {
    if (id >= 0 && id < props.images.length) setId(id);
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="image-container">
      <div className="image-grid-container">
        {images.map((el: any, index: number) => (
          <img
            key={index}
            onClick={() => handleImageClick(index)}
            src={el.photo}
            alt=""
          />
        ))}
      </div>

      {props.images.length >= 5 && (
        <div onClick={() => handleImageClick(0)} className="show-more">
          <BsGrid3X3Gap
            color="white"
            style={{ marginTop: "-3px", marginRight: "4px" }}
          />{" "}
          Show all photos
        </div>
      )}

      {showModal && (
        <ImageDisplaySection
          handleClose={handleClose}
          handleId={handleId}
          images={props.images}
          id={id}
        />
      )}
    </div>
  );
}

export default ImageGridDisplay;
