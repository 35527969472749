import BookingDetailsContainer from "./component/BookingDetailsContainer";
import HeaderSectionContainer from "components/header-section";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import FooterSection from "components/footer-section/FooterSection";
import MobileSearchContainer from "components/MobileSearch/MobileSearchContainer";

function BookingDetailsPage() {
  return (
    <>
      {!useCheckMobileDevice() ? (
        <HeaderSectionContainer
          activeNavbar={true}
          hideSearchOnInitialRender={true}
        />
      ) : (
        <MobileSearchContainer />
      )}

      <BookingDetailsContainer />

      <FooterSection />
    </>
  );
}

export default BookingDetailsPage;
