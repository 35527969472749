import { SearchActionType as ACTIONS } from "store/actions";

let dateObj = new Date();
let startDateTimestamp = dateObj.setDate(dateObj.getDate() + 1);
let startDateObj = new Date(startDateTimestamp);
let month = String(startDateObj.getMonth() + 1).padStart(2, "0");
let day = String(startDateObj.getDate()).padStart(2, "0");
let year = startDateObj.getFullYear();
let outputDate = month + "-" + day + "-" + year;

let endDateTimestamp = dateObj.setDate(dateObj.getDate() + 1);
let endDateObj = new Date(endDateTimestamp);
let month1 = String(endDateObj.getMonth() + 1).padStart(2, "0");
let day1 = String(endDateObj.getDate()).padStart(2, "0");
let year1 = endDateObj.getFullYear();
let outputDate1 = month1 + "-" + day1 + "-" + year1;

const initialState = {
  checkInDate: "",
  checkOutDate: "",
  locationId: "",
  locationName: "",
  adult: 0,
  children: 0,
  infant: 0,
};

export function SearchReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.UPDATE_SEARCH_PARAMS:
      return Object.assign({}, state, {
        ...state,
        ...action.payload,
      });

    default:
      return state;
  }
}
