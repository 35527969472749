import ProgressBar from "shared-components/progressBar";
import styles from "./style.module.scss";

type ratingObject = {
  id: number;
  type: string;
  avgRating: number;
};

type Props = {
  ratings: ratingObject[];
  inOneRow: boolean;
};

const Rating = (props: Props) => {
  const { ratings } = props;

  return (
    <div className={styles.ratingWrapper}>
      {ratings.map((rating, index) => (
        <div
          key={index}
          className={`${styles.rating} ${props.inOneRow ? styles.inOneRow : ""
            }`}
        >
          <div className={styles.type}>{rating.type}</div>
          <div className={styles.barWrapper}>
            <div className={styles.bar}>
              <ProgressBar
                progressPercentage={(rating.avgRating / 5) * 100}
                foregroundColor="black"
              />
            </div>
            {`${rating.avgRating}.0`.slice(0, 3)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Rating;
