import AddGuestsContainer from "./AddGuests/AddGuestsContainer";
import LocationSearchContainer from "./LocationSearch/LocationSearchContainer";
import MobileCalendarContainer from "./MobileCalendar/MobileCalendarContainer";

type Props = {
  activeIndex: number;
  handleBack: () => void;
  handleNext: () => void;
  handleClose: () => void;
};

function MobileSearch(props: Props) {
  return (
    <>
      {props.activeIndex === 1 && (
        <LocationSearchContainer
          handleBack={props.handleBack}
          handleNext={props.handleNext}
          handleClose={props.handleClose}
        />
      )}

      {props.activeIndex === 2 && (
        <MobileCalendarContainer
          handleBack={props.handleBack}
          handleNext={props.handleNext}
          handleClose={props.handleClose}
        />
      )}

      {props.activeIndex === 3 && (
        <AddGuestsContainer
          handleBack={props.handleBack}
          handleNext={props.handleNext}
          handleClose={props.handleClose}
        />
      )}
    </>
  );
}

export default MobileSearch;
