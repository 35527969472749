import moment from "moment";
import styles from "./style.module.scss";

type Props = {
  bookingDetails: any;
  user: any;
};

function BookingReceipt(props: Props) {
  const { bookingDetails, user } = props;

  return (
    <div id="invoice" className={styles.bookingReceiptContainer}>
      <div className={styles.idWrapper}>
        <div className={styles.reveiptId}>
          Receipt ID: {bookingDetails.booking_id}
          {moment(bookingDetails.booking_date).format("YYYYMMDD")}
        </div>
      </div>

      <div className={styles.cardWrapper}>
        <div
          className={`${styles.bookingDetailsCard} ${styles.receiptWrapper}`}
        >
          <div className={styles.bookingLocation}>
            {bookingDetails.propertyName}
          </div>
          <div className={styles.bookingNights}>
            {bookingDetails.of_nights} night in {bookingDetails.propertyName}
          </div>
          <hr />

          <div className={styles.bookingDates}>
            {moment(bookingDetails.start_date).format("ddd, DD MMM, YYYY")} -{" "}
            {moment(bookingDetails.end_date).format("ddd, DD MMM, YYYY")}
          </div>
          <div className={styles.bookingHotelType}>
            Entire home/apt - {bookingDetails.rooms_required} bed -{" "}
            {Number(bookingDetails.of_adults) +
              Number(bookingDetails.of_children) +
              Number(bookingDetails.of_infants)}{" "}
            guest
          </div>
          <div className={styles.description}>{bookingDetails.address}</div>
          <div className={styles.description}>
            Hosted by {bookingDetails.host_name}
          </div>
          <div className={styles.description}>
            <div>
              Status: <b>{bookingDetails.booking_status}</b>
            </div>
            Confirmation code: <b>{bookingDetails.confirmation_code}</b>
          </div>

          <hr />

          <div className={styles.description}>
            Guest: {bookingDetails.guest_name}
          </div>
          <div className={styles.description}>
            Cancel before 10:00 AM on Dec 30 and get a full refund for the
            nights that remain 24 hours after you cancel, minus the service
            andcleaning fees
          </div>
          <div className={styles.description}>
            Cutoff times are based on the listing's local time
          </div>
        </div>
        <div className={styles.bookingDetailsCard}>
          <div className={styles.bookingLocation}>
            <b>Price breakdown</b>
          </div>
          <div className={styles.itemWrapper}>
            <div className={styles.bookingDates}>
              ₹{bookingDetails.daily_price} x {bookingDetails.of_nights} night
            </div>
            <div className={styles.bookingDates}>
              ₹
              {Number(bookingDetails.daily_price) *
                Number(bookingDetails.of_nights)}
            </div>
          </div>
          <div className={styles.itemWrapper}>
            <div className={styles.bookingDates}>Serivice fee</div>
            <div className={styles.bookingDates}>
              ₹{bookingDetails.internet}
            </div>
          </div>
          <hr />
          <div className={styles.itemWrapper}>
            <div className={styles.bookingNights}>Total (INR)</div>
            <div className={styles.bookingNights}>
              ₹{Number(bookingDetails.total_price)}
            </div>
          </div>
        </div>
        <div className={styles.bookingDetailsCard}>
          <div className={styles.itemWrapper}>
            <div className={styles.bookingLocation}>
              <b>Payment</b>
            </div>
            <b>{bookingDetails.payment_status}</b>
          </div>
          <div className={styles.itemWrapper}>
            <div className={styles.bookingDates}>{user.user.name}</div>
            <div className={styles.bookingDates}>
              ₹{Number(bookingDetails.amount_paid)}
            </div>
          </div>
          <div className={styles.itemWrapper}>
            <div className={styles.description}>
              {moment(bookingDetails.booking_date).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )}
            </div>
            <div className={styles.bookingDates}></div>
          </div>
          <hr />

          <div className={styles.itemWrapper}>
            <div className={styles.bookingNights}>Amount paid (INR)</div>
            <div className={styles.bookingNights}>
              ₹{Number(bookingDetails.amount_paid)}
            </div>
          </div>
        </div>
      </div>

      <div>
        <strong>Got a question?</strong>
        <div>Visit the Help Centre</div>
      </div>

      <hr />

      <div className={styles.description}>
        <div className={styles.subHeading}>
          <strong>Occupancy taxes</strong>
        </div>
        Occupancy Taxes include Exempt - SGST (India Delhi State Gst), Exempt -
        CGST (India Delhi Central Gst).
        <div className={styles.subHeading}>
          <strong>BlaBlaStay Payments India Pvt Ltd</strong>
        </div>
        BlaBlaStay Payments is a limited payment collection agent of your Host.
        It means that upon your payment of the Total Fees to BlaBlaStay
        Payments, your payment obligation to your Host is satisfied. Refund
        requests will be processed in accordance with: (i) the Host's
        cancellation policy (available on the Listing); or (ii) BlaBlaStay's
        Guest Refund Policy Terms, available at www.BlaBlaStay.com/terms.
        Questions or complaints: contact BlaBlaStay Payments India Pvt Ltd on
        apac-payments-legal@BlaBlaStay.com.
      </div>
    </div>
  );
}

export default BookingReceipt;
