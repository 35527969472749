import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdClose } from 'react-icons/io';
import '../styles/location-field.scss';

import LocationList from './LocationList';
import { AppDispatch } from 'helpers/GenericType';
import { updateSearchParams, searchLocation } from 'store/actions';

type Props = {
    activeOption: number,
    setActiveOption: (id: number) => void
}

function LocationField(props: Props) {

    const dispatch: AppDispatch = useDispatch();
    const { search }: any = useSelector(state => state);
    const [searchText, setSearchText] = useState("");
    const [locationList, setLocationList] = useState([]);

    useEffect(() => {
        if (searchText === "") setSearchText(search.locationName);
    }, [search]);

    const onChange = (e: any) => {
        if (e.target.value !== "") {
            const params = { location_keywords: e.target.value };
            dispatch(searchLocation(params))
            .then((res: any) => setLocationList(res));
        }
        setSearchText(e.target.value);
    }

    const handleOnClick = (params: any) => {
        setSearchText(params.name);
        dispatch(updateSearchParams({
            locationId: params.id,
            locationName: params.name
        }));
        props.setActiveOption(2);
    }

    const handleClear = () => {
        setSearchText("");
        dispatch(updateSearchParams({
            locationId: "",
            locationName: ""
        }));
    }

    const isEmpty = searchText?.length !== 0;

    const active = props.activeOption === 1;

    return (
        <Fragment>

            {
                (active) && <LocationList locationList={locationList} onClick={handleOnClick} />
            }

            <div className={`location-field-container ${active && 'active'}`}>

                <div className="text-input-container">

                    <div className="location-heading">Location</div>

                    <input
                        type="text"
                        value={searchText}
                        onChange={onChange}
                        className={`location-input ${props.activeOption !== 0 && 'reduce-width'}`}
                        placeholder="Where are you going?" />

                </div>

                <div className={`cross-icon ${(active && isEmpty) && 'visible'}`} onClick={handleClear}>
                    <IoMdClose />
                </div>

            </div>
        </Fragment>
    )
}

export default LocationField;