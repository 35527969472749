import React, { useEffect } from 'react';

import './style.scss';
import Footer from './Footer';
import { footerLinks } from 'jsonData/homePage.json';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { navigateTo, Navigation } from 'helpers/Navigation';
import { getHomePageData } from 'store/actions';

function FooterSection() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { master }: any = useSelector(state => state);

    const handleClick = (id:string) => {
        navigateTo(Navigation.footerLinkPage, history, {id: id});
    }

    useEffect(() => {
        dispatch(getHomePageData({}))
    }, []);

    const renderFooterLinks = () => {
        const list = master.footerLinks || [];
        return list.map((links: any) => (

            <div key={links.id} className="col-lg-3 col-md-6 col-md-sm-12">
                <div className="footer-section-links-heading">{links.heading}</div>
                <div className="footer-section-links">
                    {
                        links.list.map((link: any) => <div onClick={() => handleClick(link.id)} key={link.id}>{link.name}</div>)
                    }
                </div>
            </div>
        ))
    }

    return (
        <div className="container-fluid footer-section-container">
            <div className="row footer-section-style">
                <div className="col-lg-11 col-md-12 col-sm-12 p-0">

                    <div className="footer-section-links-container">
                        <div className="row">

                            {renderFooterLinks()}

                        </div>
                    </div>

                    <Footer />

                </div>
            </div>
        </div>
    )
}

export default React.memo(FooterSection);
