export function formatDateUsingArray(date: any) {
  var dateArray = date.split("-");
  let newDate = "";
  return (newDate = dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1]);
}

export function todayDate() {
  var date = new Date();
  var today = date.toISOString().split("T")[0];
  return today;
}

export function tomorrowDate() {
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  var tomorrow = currentDate.toISOString().split("T")[0];

  return tomorrow;
}
//testing
