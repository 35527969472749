import { useState } from 'react';
import BottomNavigationMenu from './BottomNavigation';
import useCheckMobileDevice from 'helpers/useCheckMobileDevice';
import { useHistory } from 'react-router';
import { navigateTo, Navigation } from '../../helpers/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveOption } from 'store/actions';

function BottomNavigationContainer() {

  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useCheckMobileDevice();
  const { user }: any = useSelector(state => state);

  const [showMenu, setShowMenu] = useState(true);
  const [currentSelection, setCurrentSelection] = useState(1);

  const handleClick = (selection: number) => {
    setCurrentSelection(selection);
    if (selection === 0) {
      dispatch(changeActiveOption(0));
    }
    if (selection === 1) {
      navigateTo(Navigation.home, history);
    }
    if (selection === 2 && user.user) {
      navigateTo(Navigation.myBookings, history);
    }
    if (selection === 3) {
      dispatch(changeActiveOption(selection));
    }
    if (selection === 4) {
      navigateTo(Navigation.profile, history);
    }
    if (selection === 5) {
      localStorage.clear();
      history.go(0);
    }
  }

  document.addEventListener('scroll', () => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) setShowMenu(false);
    else setShowMenu(true);
  });

  return (
    !isMobile ? <div /> :
      <BottomNavigationMenu
        user={user.user}
        showMenu={showMenu}
        currentSelection={currentSelection}
        handleClick={handleClick}
        activeOption={user.activeOption}
      />
  )
}

export default BottomNavigationContainer;
