import React, { Fragment } from 'react';
import { BsStar, BsStarFill } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';
import { MdVerifiedUser } from 'react-icons/md';

function HostDetailsPage() {

    const renderListings = () => {
        return <div className="d-flex host-listing-list">
            <div className="host-details-listing">
                <img className="image" src="https://www.gannett-cdn.com/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg" />
                <div className="rating"><BsStarFill color="tomato" /> 4.0 <span className="text-secondary">(4)</span></div>
                <div className="desc">Private room - House</div>
                <div className="desc">UVs Homestay Haridwar - Airy and Spacing</div>
            </div>,
             <div className="host-details-listing">
                <img className="image" src="https://www.gannett-cdn.com/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg" />
                <div className="rating"><BsStarFill color="tomato" /> 4.0 <span className="text-secondary">(4)</span></div>
                <div className="desc">Private room - House</div>
                <div className="desc">UVs Homestay Haridwar - Airy and Spacing</div>
            </div>
        </div>
    }

    const renderReviews = () => {
        return (
            <div className="host-review">
                <div className="rtitle">UVs Homestay Haridwar - Comfortable room</div>
                <div className="subtitle">March 2021</div>
                <div className="desc mt-3">It was a great stay at Seema's place. She is a wonderful host . We were very comfortable like home .</div>
                <div className="d-flex align-items-center mt-4">
                    <img src="https://i.imgur.com/V3ICjlm.jpg" className="image mr-3" />
                    <div>
                        <div className="name">Manish, India</div>
                        <div className="subtitle">Jonied in 2019</div>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className="host-details-page">
            <div>
                <div className="left-container">
                    <img className="image-style" src="https://i.imgur.com/V3ICjlm.jpg" />
                    <div className="review-count"><BsStar size={24} className="mr-1" /> 45 reviews</div>
                    <div className="review-count"><MdVerifiedUser size={24} className="mr-1" /> Identity verified</div>
                    <hr className="mt-4 mb-4" />
                    <div className="host-desc">Seema Confirmed</div>
                    <div className="host-desc2"><TiTick size={25} /> Identity</div>
                    <div className="host-desc2"><TiTick size={25} /> Email address</div>
                    <div className="host-desc2"><TiTick size={25} /> Phone number</div>
                    <div className="host-desc3"><strong><u>Learn more </u></strong>
                 about how confirming account info helps keep the Airbnb community secure.
                </div>
                </div></div>

            <div className="right-container">
                <div className="title">Hi, I'm Seema</div>
                <div className="subtitle">Joined in 2019</div>

                <div className="heading mt-5">About</div>
                <div className="description">I've been living in Haridwar for the past six years and was working as a senior manager in a luxury resort before venturing to start a homestay for  a better work life balance. Being a hospitality… <strong><u>read more</u></strong></div>
                <div className="review-count"><BsStar size={24} className="mr-1" /> 45 reviews</div>
                <div className="review-count mb-5"><MdVerifiedUser size={24} className="mr-1" /> Identity verified</div>
                <hr />

                <div className="heading">Seema's listings</div>
                {renderListings()}

                <hr />
                <div className="heading"><BsStarFill /> 45 reviews</div>
                {renderReviews()}
                {renderReviews()}
                {renderReviews()}
                {renderReviews()}
   
                <div className="show-more-btn">Show more reviews</div>

            </div>

        </div>
    )
}

export default HostDetailsPage;
