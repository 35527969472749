import React from "react";
import countryData from "country-telephone-data";
import { FormSelect } from "shared-components";

type Props = {
  name: string;
  label: string;
};

export function CountrySelector(props: Props) {
  
  let value = "+91";

  const formattedOptions = () => {
    return countryData.allCountries.map((allCountry) => {
      return {
        listValue: "+" + allCountry.dialCode,
        listText: allCountry.name.split(" ")[0] + " (+" + allCountry.dialCode + ")",
      };
    });
  };

  return (
    <FormSelect name={props.name} value={value} label={props.label} options={formattedOptions()} />
  );
}
