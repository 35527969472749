import React from 'react';
import Images from 'utilities/Images';

type Props = {
    hotelDetails: any,
    handleClick: (hotelId: string) => void,
}

function HotelItem(props: Props) {

    const hotel = props.hotelDetails;

    const handleClick = () => props.handleClick(hotel.id);

    const renderFeaturedAmnenty = () => {
        const arr = [];
        if (hotel.featured_amnenty_1) arr.push(hotel.featured_amnenty_1);
        if (hotel.featured_amnenty_2) arr.push(hotel.featured_amnenty_2);
        if (hotel.featured_amnenty_3) arr.push(hotel.featured_amnenty_3);
        if (hotel.featured_amnenty_4) arr.push(hotel.featured_amnenty_4);
        return arr.join(" · ")
    }

    return (
        <div className="hotel-item-card" onClick={handleClick}>
            <img src={hotel.photo || Images.noImage} className="image" />

            <div className="description-container">
                <div className="hotel-item-type-icon">
                    <div className="hotel-item-type">{hotel.propcodename}</div>
                </div>
                <div className="hotel-item-name">{hotel.sub_property_name}</div>
                <div className="hotel-item-rooms-type">{hotel.sub_property_config}</div>
                <div className="hotel-item-rooms-type">{hotel.property_type}</div>
                <div className="hotel-item-amenities">{renderFeaturedAmnenty()}</div>

                <div className="hotel-item-rating-price">
                    <div className="hotel-item-price">
                        ₹ {props.hotelDetails.price_per_night || 0} <span className="per-night">/night</span>
                    </div>
                    <div className="hotel-item-rating">
                        <span className="star">{hotel.avgrating && "★"}</span>
                        <span className="rating">{hotel.avgrating}</span>
                        <span className="users">{hotel.reviewcount && `(${hotel.reviewcount})`}</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HotelItem;
