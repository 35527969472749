const NodeAPI = Object.freeze({
  // Auth
  loginViaGooogle: "/api/auth/google-login",

  //Reviews
  getPropertyReviews: "/api/reviews",
  getPropertyReviewTypeList: "/api/reviews/types",
  postPropertyReview: "/api/reviews/",
  checkPropertyReview: "/api/reviews/check",

  //Bookings
  getMyBookings: "/api/bookings",
  getBookingReviewByCode: "/api/bookings/getByCode",

  //User
  getProfileData: "/api/user",
  updateProfileData: "/api/user",
});

export default NodeAPI;
