import { AppDispatch } from 'helpers/GenericType';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FormButton, Notification } from 'shared-components';
import { cancelBooking, getCancelDetails } from 'store/actions';

type Props = {
    bookingDetails: any
}

function CancelBooking(props: Props) {

    const history = useHistory();
    const dispatch: AppDispatch = useDispatch();
    const bookingData = props.bookingDetails.booking_data || {};
    const [cancelDetails, setCancelDetails] = useState(null) as any;
    const [showLoader, setShowLoader] = useState(false);

    const handleCancel = () => {
        setShowLoader(true);
        dispatch(getCancelDetails({ booking_id: bookingData.id }))
            .then((res: any) => setCancelDetails(res))
            .finally(() => setShowLoader(false))
    }

    const handleConfirmCancel = () => {
        setShowLoader(true);
        const param = {
            booking_id: cancelDetails.booking_data.id,
            cancellation_id: cancelDetails.cancellation_data.cancellation_id
        }
        dispatch(cancelBooking(param))
            .then((res: any) => {
                Notification('Cancel Booking', 'Your booking has been cancelled successfully!');
                history.go(0);
            })
            .finally(() => setShowLoader(false))
    }

    return (
        <div>
            {
                (props.bookingDetails.allow_cancel && !cancelDetails) &&
                <div className="row pr-2 mt-4">
                    <div className="col-lg-10 col-md-8 col-sm-6"></div>
                    <div className="col-lg-2 col-md-4 col-sm-6">
                        <FormButton onClick={handleCancel} showLoader={showLoader} text="Cancel" type="button" />
                    </div>
                </div>
            }

            {
                (cancelDetails) &&
                <div>
                    <h4 className="mt-4 mb-4"><strong>Cancellation Details</strong></h4>
                    <div className="ml-5">
                        <div className="mb-2"><span className="details-heading2">Policy Name: </span> {cancelDetails.cancellation_data.policy_name}</div>
                        <div className="mb-2"><span className="details-heading2">Policy Description: </span> {cancelDetails.cancellation_data.policy_desc}</div>
                        <div className="mb-2"><span className="details-heading2">Total Amount: </span> ₹{cancelDetails.cancellation_data.amount_total}</div>
                        <div className="mb-2"><span className="details-heading2">Cancellation Charges: </span> ₹{cancelDetails.cancellation_data.amount_deduct}</div>
                        <div className="mb-2"><span className="details-heading2">Percentage: </span> {cancelDetails.cancellation_data.perc_deduct}%</div>
                        <div className="mb-2"><span className="details-heading2">Refund Amount: </span> ₹{cancelDetails.cancellation_data.amount_refund}</div>

                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 mt-5">
                        <FormButton showLoader={showLoader} onClick={handleConfirmCancel} text="Confirm Cancel" type="button" />
                    </div>
                </div>
            }
        </div>
    )
}

export default CancelBooking;