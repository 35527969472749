import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchParams } from "store/actions";
import MobileCalendar from "./MobileCalendar";

type Props = {
  handleBack: () => void;
  handleNext: () => void;
  handleClose: () => void;
};

function MobileCalendarContainer(props: Props) {
  const dispatch = useDispatch();
  const { search }: any = useSelector((state) => state);
  const [activeOption, setActiveOption] = useState("startDate");

  const handleCheckIn = (startDate: any) => {
    const params = { checkInDate: moment(startDate).format("MM-DD-YYYY") };
    dispatch(updateSearchParams(params));
  };

  const handleCheckOut = (endDate: any) => {
    const params = { checkOutDate: moment(endDate).format("MM-DD-YYYY") };
    dispatch(updateSearchParams(params));
  };

  let startDate = null;
  let endDate = null;

  if (search.checkInDate) {
    startDate = moment(search.checkInDate, "MM-DD-YYYY");
  }

  if (search.checkOutDate) {
    endDate = moment(search.checkOutDate, "MM-DD-YYYY");
  }

  const handleClear = () => {
    dispatch(
      updateSearchParams({
        checkInDate: "",
        checkOutDate: "",
      })
    );
  };

  return (
    <MobileCalendar
      startDate={startDate}
      endDate={endDate}
      activeOption={activeOption}
      handleClear={handleClear}
      setActiveOption={setActiveOption}
      handleCheckIn={handleCheckIn}
      handleCheckOut={handleCheckOut}
      handleNext={props.handleNext}
      handleBack={props.handleBack}
    />
  );
}

export default MobileCalendarContainer;
