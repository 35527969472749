import React, { useState } from 'react';
import './styles/signup-style.scss';
import OtpInput from 'react-otp-input';
import { FormButton, Modal } from 'shared-components';

type Props = {
    error: string,
    mobile: string,
    handleBack: () => void,
    handleClose: () => void,
    handleSubmit: (params: any) => void,
}

function VerifyOtp(props: Props) {

    const [otp, setOtp] = useState('');

    const isDisabled = otp.length !== 6;

    const onChange = (otp: string) => setOtp(otp);

    const handleSubmit = () => props.handleSubmit(otp);

    return (
        <Modal heading="Otp verification" visible={true} handleClose={props.handleBack}>
            <div className="otp-container">

                <OtpInput value={otp}
                    numInputs={6}
                    onChange={onChange}
                    isInputNum={true}
                    containerStyle={{ display: "flex", justifyContent: "center", marginBottom: "24px" }}
                    inputStyle={{ width: "40px", height: '40px' }}
                    separator={<span style={{ width: '8px' }}></span>} />

                <div className="error">{props.error}</div>

                <div className="description mt-1">Otp sent on {props.mobile}</div>
                <div className="description">Please enter your otp receviced on your mobile.</div>

                <FormButton onClick={handleSubmit} text="Submit" type="button" disabled={isDisabled} />

            </div>
        </Modal>
    )
}

export default VerifyOtp;
