import { useState } from "react";
import Divider from "../Divider";
import "./style.scss";

type Props = {
  policies: any;
};

function Policies(props: Props) {
  const MAX_SIZE = 4;
  const [showFullList, setShowFullList] = useState(false);
  const [showFullList2, setShowFullList2] = useState(false);
  const [showFullList3, setShowFullList3] = useState(false);

  const houseRules = props.policies.HouseRules || [];
  const accessibility = props.policies.Accessibility || [];
  const healthAndSafety = props.policies.HealthSafety || [];

  const handleMoreDetails = () => setShowFullList(!showFullList);
  const handleMoreDetails2 = () => setShowFullList2(!showFullList2);
  const handleMoreDetails3 = () => setShowFullList3(!showFullList3);

  const tempHouseRules = [...houseRules];
  const tempAccessibility = [...accessibility];
  const tempHealthAndSafty = [...healthAndSafety];

  if (!showFullList) tempHouseRules.length = MAX_SIZE;
  if (!showFullList2) tempHealthAndSafty.length = MAX_SIZE;
  if (!showFullList3) tempAccessibility.length = MAX_SIZE;

  const renderPolicies = (
    policies: { id: string; policy_name: string }[],
    isHouse: boolean
  ) =>
    policies.map((policy) => (
      <ul className="policy-list" key={policy.id}>
        <li
          className={`policy-item ${!isHouse && "active-text"}`}
          key={policy.id}
        >
          {policy.policy_name}
        </li>
      </ul>
    ));

  let isEmpty = false;
  if (
    props.policies.HouseRules.length === 0 &&
    props.policies.Accessibility.length === 0 &&
    props.policies.HealthSafety.length === 0
  ) {
    isEmpty = true;
  }

  return isEmpty ? (
    <div />
  ) : (
    <>
      <Divider />
      <div className="policy-container">
        <div className="flex-col">
          <h4 className="mb-4">
            <strong>House Rules</strong>
          </h4>
          {renderPolicies(tempHouseRules, true)}

          {tempHouseRules.length !== 0 && houseRules.length >= MAX_SIZE && (
            <u
              className="color-primary ml-2 btn font-weight-bold"
              onClick={handleMoreDetails}
            >
              {!showFullList ? "More Details" : "Less Details"}
            </u>
          )}
        </div>

        <div className="flex-col">
          <h4 className="mb-4">
            <strong>Health & Safety</strong>
          </h4>
          {renderPolicies(tempHealthAndSafty, true)}

          {tempHealthAndSafty.length !== 0 &&
            healthAndSafety.length >= MAX_SIZE && (
              <u
                className="color-primary btn ml-2 font-weight-bold"
                onClick={handleMoreDetails2}
              >
                {!showFullList2 ? "More Details" : "Less Details"}
              </u>
            )}
        </div>

        <div className="flex-col">
          <h4 className="mb-4">
            <strong>Accessibility</strong>
          </h4>
          {renderPolicies(tempAccessibility, true)}

          {tempAccessibility.length !== 0 &&
            accessibility.length >= MAX_SIZE && (
              <u
                className="color-primary btn ml-2 font-weight-bold"
                onClick={handleMoreDetails3}
              >
                {!showFullList3 ? "More Details" : "Less Details"}
              </u>
            )}
        </div>
      </div>
    </>
  );
}

export default Policies;
