export const Pattern = {
    name: {
        pattern: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/,
        message: "Numbers not allowed",
        isAlphabetOnly: true,
    },
    email: {
        pattern: 
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Please enter valid email.",
    },
    uid: {
        pattern: /^\d{12}$/,
        message: "Please enter valid UID.",
        isNumberOnly: true,
    },
    pan: {
        pattern: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
        message: "Please enter valid pan."
    },
    mobile: {
        pattern: /^[6-9]\d{9}$/,
        message: "Please enter valid mobile",
        isNumberOnly: true,
    },
    phone: {
        pattern: /^[6-9]\d{9}$/,
        message: "Please enter valid phone",
        isNumberOnly: true,
    },
    telephone: {
        pattern: /^\d{4,25}$/,
        message: "Please enter valid telephone",
        isNumberOnly: true,
    },
    account: {
        pattern: /^\d{3,25}$/,
        message: "Please enter valid account number",
        isNumberOnly: true,
    },
    ifsc: {
        pattern: /^\d{11}$/,
        message: "Please enter valid IFSC code."
    },
    zipcode: {
        pattern: /^\d{6}$/,
        message: "Please enter valid Zipcode.",
        isNumberOnly: true,
    },
    seats: {
        pattern: /^\d{1,2}$/,
        message: "Please enter valid number",
        isNumberOnly: true,
    },
    amount: {
        pattern: /^\d+(\.\d{1,2})?$/,
        message: "Please enter valid amount",
        isNumberOnly: true,
    },
    number: {
        pattern:  /^\d{1,20}$/,
        message: "Please enter valid number",
        isNumberOnly: true,
    },
};