import React, { useEffect, useState } from 'react';

const messages = [
    'Holiday Rental Homes  & Hotels',
    'Short-Term Furnished Apartments',
    'No Booking Fees. (Commision free booking)',
    'Vocal for Local Hosts',
];

// function TextAnimation() {
//     const [text, setText] = useState("");
//     const [counter, setCounter] = useState(0);
//     const [reverse, setReverse] = useState(false);
//     const [messageCounter, setMessageCounter] = useState(0);

//     useEffect(() => {
//         const timeout = setTimeout(() => {
//             const string = messages[messageCounter];

//             const stringArr = string.split("");
//             if (!reverse) {
//                 if (stringArr[counter]) {
//                     setText(text + stringArr[counter]);
//                     setCounter(counter + 1);
//                     setReverse(false);
//                 } else {
//                     setReverse(true);
//                     setText(text);
//                     setCounter(counter - 1);
//                 }
//             } else {
//                 if (stringArr[counter]) {
//                     const str = string.substring(0, counter);
//                     setText(str);
//                     setCounter(counter - 1);
//                 } else {
//                     setCounter(0);
//                     setReverse(false);
//                     if (messageCounter < 3) {
//                         setMessageCounter(messageCounter + 1);
//                     } else {
//                         setMessageCounter(0);
//                     }
//                 }
//             }
//         }, 150);

//         return () => clearTimeout(timeout);

//     }, [counter, reverse, messageCounter]);

//     return <h3 className="animation-text">{text}</h3>

// }

// export default React.memo(TextAnimation);

function TextAnimation() {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (counter === messages.length-1) setCounter(0);
            else setCounter(counter + 1);
        }, 1500);

        return () => clearTimeout(timeout);
    }, [counter]);

    let text = messages[counter];

    return <h3 className="animation-text">{text}</h3>

}

export default React.memo(TextAnimation);
