import React from "react";
import styles from "./style.module.scss";

type Props = {
  showLoader: boolean;
  children: React.ReactChild;
};

export function Loader(props: Props) {
  return (
    <div>
      <p
        className={styles.loading}
        style={{ display: props.showLoader ? "block" : "none" }}
      >
        .
      </p>

      <div style={{ display: props.showLoader ? "none" : "block" }}>
        {props.children}
      </div>
    </div>
  );
}
