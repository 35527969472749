import { actionDispatcher } from "store/actionDispatcher";
import API from "api-handler/apiType";

export const MasterActionType = {
    GET_HOME_PAGE_DATA: "GET_HOME_PAGE_DATA",
    GET_FOOTER_LINK_DATA: "GET_FOOTER_LINK_DATA",
};

export const getHomePageData = (params: object) => (dispatch: any) => {
    return actionDispatcher(
        params,
        MasterActionType.GET_HOME_PAGE_DATA,
        API.getHomePageData,
        dispatch,
        "get"
    );
};


export const getFooterLinkData = (params:object) => (dispatch: any) => {
    return actionDispatcher(
        params,
        MasterActionType.GET_FOOTER_LINK_DATA,
        API.getFooterLinkData,
        dispatch,
        "get"
    );
}