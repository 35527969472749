export const getQueryParamsFromUrl = () => {
  const queryParams = new URLSearchParams(window.location.search);
  let tempQueryParams: any = {};

  // @ts-ignore
  for (const [key, value] of queryParams) {
    tempQueryParams[key] = value;
  }

  if (Object.keys(tempQueryParams).length !== 0) return tempQueryParams;
  else return null;
};

export const getCurrencySymbol = (currency: string) => {
  switch (currency.toLowerCase()) {
    case "inr":
      return "₹";
    case "eur":
      return "€";
    case "gbp":
      return "£";
    case "aud":
      return "A$";
    case "cad":
      return "C$";
    case "aed":
      return "د.إ";
    case "sar":
      return "ر.س";
    case "myr":
      return "RM";
    case "omr":
      return "﷼";
    default:
      return "$";
  }
};

export const formatAmount = (amount: any, roundUpto = 0, currency = "inr") => {
  let format = "en-IN";
  if (currency.toLowerCase() !== "inr") {
    format = navigator.language;
  }

  return Number(amount).toLocaleString(format, {
    maximumFractionDigits: roundUpto,
  });
};

const b64DecodeUnicode = (str: string) =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(str),
        (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join("")
  );

export const parseJwt = (token: string) => {
  var base64Url = token?.split(".")[1] || "";
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(b64DecodeUnicode(base64));
};
