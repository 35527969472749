import { actionDispatcher, dotNetActionDispatcher, nodeActionDispatcher } from "store/actionDispatcher";
import API from "api-handler/apiType";
import NodeAPI from "api-handler/nodeApiType";
import DotNetAPI from "api-handler/dotNetApiType";

export const AuthActionType = {
  LOGIN_AND_SIGNUP: 'LOGI_AND_SIGNUP',
  LOGIN_VIA_GOOGLE: 'LOGIN_VIA_GOOGLE',
  VERIFY_OTP: 'VERIFY_OTP',
  CHANGE_ACTIVE_OPTION: 'CHANGE_ACTIVE_OPTION',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  USER_UPDATE: 'USER_ACTION'
};

export const loginAndSignup = (mobile: string) => (dispatch: any) => {
  var params = {};
  return dotNetActionDispatcher(
    params,
    AuthActionType.LOGIN_AND_SIGNUP,
    DotNetAPI.sendLoginOTP+"/"+mobile,
    dispatch,
    'post'
  )
};

export const loginViaGoogle = (params: object) => (dispatch: any) => {
  return nodeActionDispatcher(
    params,
    AuthActionType.LOGIN_VIA_GOOGLE,
    NodeAPI.loginViaGooogle,
    dispatch,
    "post"
  );
};

export const verifyOtp = (params: object) => (dispatch: any) => {
  return actionDispatcher(
    params,
    AuthActionType.VERIFY_OTP,
    API.verifyOtp,
    dispatch,
    "post"
  );
};

export const changePassword = (params: object) => (dispatch: any) => {
  return actionDispatcher(
    params,
    AuthActionType.CHANGE_PASSWORD,
    API.changePassword,
    dispatch,
    "post"
  );
};



export const changeActiveOption = (params: number) => (dispatch: any) => dispatch({
  type: AuthActionType.CHANGE_ACTIVE_OPTION,
  payload: params
})