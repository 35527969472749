import React from 'react';

function Divider() {
    return (
        <div style={{
            height: '1px',
            backgroundColor: '#f0f0f0',
            width:'100%',
            margin: '32px 0px'
        }}></div>
    )
}

export default Divider;
