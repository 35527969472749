import React, { Fragment } from 'react';
import Divider from '../Divider';
import './style.scss';

type Props = {
    latitude: string,
    longitude: string,
}

function MapLocation(props: Props) {
    return (
        <Fragment>
            <Divider />
            <div className="map-location-style">
                <h4><strong>Location</strong></h4>
                <div className="gmap_canvas">
                    <iframe width="600" className="map-container" style={{ height: "60vh", width: '100%' }} id="gmap_canvas" src={`https://maps.google.com/maps?q=${props.latitude},${props.longitude}&t=&z=13&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no"></iframe>
                </div>
            </div>
        </Fragment>
    )
}

export default MapLocation
