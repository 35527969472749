import { AiOutlineClose } from "react-icons/ai";
import "./style.scss";

type Props = {
  children: any,
  visible: boolean;
  heading: string,
  containerClass?: string,
  className?: string;
  headerClass?: string;
  bodyClass?: string;
  handleClose?: () => void;
};

export function Modal(props: Props) {

  const handleClick = (e: any) => e.stopPropagation();

  return (
    <div className={`modal-container ${!props.visible && 'invisible'} ${props.containerClass}`}>
      <div className={`modal-box ${props.className}`} onClick={handleClick} >

        <>

          <div className={`modal-heading-container ${props.headerClass}`}>
            <AiOutlineClose size={18} className="modal-close-icon" onClick={props.handleClose} />
            <div className="modal-heading">{props.heading}</div>
            <div></div>
          </div>

          <div className={`modal-content-container ${props.bodyClass}`}>{props.children}</div>

        </>

      </div>
    </div>
  );
};

export default Modal;
