import { IoIosSearch, IoMdClose } from "react-icons/io";
import "./style.scss";
import LocationList from "./LocationList";

type Props = {
  active: boolean;
  searchText: string;
  locationList: any[];
  setActive: (status: boolean) => void;
  onChange: (e: any) => void;
  handleClear: () => void;
  handleClick: (params: any) => void;
};

function LocationSearch(props: Props) {
  return (
    <div className="location-search-container">
      <div className={`search-input-container `}>
        <div className={`input-container `}>
          <IoIosSearch size={24} className="search-icon" />
          <input
            className="input"
            onFocus={() => props.setActive(true)}
            value={props.searchText}
            onChange={props.onChange}
            placeholder="Where are you going?"
          />

          {props.searchText !== "" && (
            <IoMdClose
              size={24}
              className="cross-icon"
              onClick={props.handleClear}
            />
          )}
        </div>

        {props.active && (
          <div className="text-style" onClick={props.handleClear}>
            Cancel
          </div>
        )}
      </div>

      {props.active && (
        <LocationList
          locationList={props.locationList}
          handleClick={props.handleClick}
        />
      )}
    </div>
  );
}

export default LocationSearch;
