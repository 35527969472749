import { useEffect, useState } from "react";
import "./style.scss";
import LocationSearch from "./LocationSearch";
import { AppDispatch } from "helpers/GenericType";
import { useDispatch, useSelector } from "react-redux";
import { searchLocation, updateSearchParams } from "store/actions";

type Props = {
  handleBack: () => void;
  handleNext: () => void;
  handleClose: () => void;
};

function LocationSearchContainer(props: Props) {
  const dispatch: AppDispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { search }: any = useSelector((state) => state);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    if (searchText === "") setSearchText(search.locationName);
    if (search.locationName) {
      const params = { location_keywords: search.locationName };
      dispatch(searchLocation(params)).then((res: any) => setLocationList(res));
      setSearchText(search.locationName);
    }
  }, [search]);

  const onChange = (e: any) => {
    if (e.target.value !== "") {
      const params = { location_keywords: e.target.value };
      dispatch(searchLocation(params)).then((res: any) => setLocationList(res));
    }
    setSearchText(e.target.value);
  };

  const handleClick = (params: any) => {
    setSearchText(params.name);
    dispatch(
      updateSearchParams({
        locationId: params.id,
        locationName: params.name,
      })
    );
    props.handleNext();
  };

  const handleClear = () => {
    setSearchText("");
    setActive(false);
    dispatch(
      updateSearchParams({
        locationId: "",
        locationName: "",
      })
    );
  };

  const isEmpty = searchText?.length !== 0;

  return (
    <LocationSearch
      searchText={searchText}
      locationList={locationList}
      handleClear={handleClear}
      active={active}
      setActive={setActive}
      onChange={onChange}
      handleClick={handleClick}
    />
  );
}

export default LocationSearchContainer;
