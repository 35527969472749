import React, { Fragment, useState } from 'react';
import Divider from '../Divider';
import './style.scss';

type Props = { amenities: any[] }

function Amenities(props: Props) {

    const MAX_SIZE = 8;
    const [showReadMore, setShowReadMore] = useState(false);

    let amenities = [...props.amenities];

    if (props.amenities && props.amenities.length > MAX_SIZE) {

        if (showReadMore) amenities = [...props.amenities];
        else amenities.length = 8;

    }

    const handleClick = () => setShowReadMore(!showReadMore)

    return (

        !props.amenities.length ? <div /> :
            <Fragment>
                <Divider />
                <div className="amenities-container">
                    <h4><strong>Amenities</strong></h4>

                    <div className="amenity-list-style">

                        {
                            amenities.map(amenity => (
                                <div key={amenity.id} className="amenity-item">
                                    <div className="icon-container">
                                        <img src={amenity.icon} className="icon" alt={amenity.name} />
                                    </div>
                                    <div className="name">{amenity.name}</div>
                                </div>
                            ))
                        }

                        {
                            (props.amenities.length > MAX_SIZE) &&
                            <span onClick={handleClick} className="view-more">
                                {showReadMore ? 'View Less' : 'View More'}
                            </span>
                        }

                    </div>

                </div>

            </Fragment>
    )
}

export default Amenities;
