import { HotelActionType as ACTIONS } from "store/actions";

const initialState = {
  hotelList: [],
  hotelDetails: {
    prop_master: {},
    availableFlat: {},
    sub_property_data: [],
    sub_property_show_group_individual: 1,
    amenities: [],
    activities: [],
    kitchen: [],
    host: {},
    images: [],
    policies: {
      HouseRules: [],
      cancelation: [],
      Accessibility: [],
      HealthSafety: [],
    },
    sleepingArrangements: [],
    kitchennfood: [],
    broadband: [],
  },
};

export function HotelReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.GET_HOTEL_LIST:
      return Object.assign({}, state, {
        hotelList: action.payload.propertyData || [],
      });

    case ACTIONS.GET_HOTEL_DETAILS:
      return Object.assign({}, state, {
        hotelDetails: action.payload,
      });

    case ACTIONS.GET_AVAILABLITY:
      return Object.assign({}, state, {
        hotelDetails: {
          ...state.hotelDetails,
          availableFlat: action.payload,
        },
      });

    default:
      return state;
  }
}
