import { MasterActionType as ACTIONS } from "store/actions";

const initialState = {
  
};

export function MasterReducer(state = initialState, action: any) {
  switch (action.type) {

    case ACTIONS.GET_HOME_PAGE_DATA:
      return Object.assign({}, state, {
          ...state,
          ...action.payload
      });
    
      default:
      return state;
  }
}
