import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  const token = localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (token) {
          return <Component {...routeProps} />;
        }
        return <Redirect to={{ pathname: '/', state: { from: routeProps.location } }} />
      }}
    />
  );
};

export default PrivateRoute;