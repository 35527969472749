import { useEffect, useState } from "react";
import ReactStars from "react-stars";
import { useDispatch } from "react-redux";
import { AppDispatch } from "helpers/GenericType";
import { getPropertyReviewTypeList } from "../../../store/actions/ReviewActions";
import { FormButton, FormTextArea, Loader } from "shared-components";
import styles from "./form.module.scss";

type Props = {
  bookingId: number;
  isLoading?: boolean;
  submitReview: (obj: any) => void;
};

type ratingPostObj = {
  id: number;
  type: string;
  rating: number;
};

type reviewObj = {
  review: string;
  ratings: ratingPostObj[];
};

type RatingObj = {
  id: number;
  type: string;
};

const ReviewPostForm = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [ratingTypes, setRatingTypes] = useState<RatingObj[]>([]);
  const [reviewObj, setReviewObj] = useState<reviewObj>({
    review: "",
    ratings: [],
  });

  const onChangeStar = (val: number, id: number) => {
    setReviewObj((prev) => ({
      ...prev,
      ratings: prev.ratings.map((rating) =>
        rating.id === id ? { ...rating, rating: val } : rating
      ),
    }));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    props.submitReview(reviewObj);
  };

  useEffect(() => {
    setShowLoader(true);
    dispatch(getPropertyReviewTypeList({}))
      .then((res: any) => {
        setRatingTypes(res);
      })
      .catch((err: any) => {
        console.log(err);
      }).finally(() => {
        setShowLoader(false);
      });
  }, []);

  useEffect(() => {
    setReviewObj({
      review: "",
      ratings: ratingTypes.map((ratingType) => ({ ...ratingType, rating: 0 })),
    });
  }, [ratingTypes]);

  return (
    <form className={styles.formWrapper} onSubmit={onSubmit}>
      <Loader showLoader={showLoader}>
        <div className={styles.ratingsContainer}>
          {reviewObj.ratings.map((rating) => (
            <div key={rating.id} className={styles.ratingWrapper}>
              <div className={styles.ratingLabel}>{rating.type}</div>
              <ReactStars
                className={styles.star}
                count={5}
                value={rating.rating}
                half={false}
                onChange={(val) => onChangeStar(val, rating.id)}
                size={40}
                edit={true}
                color1={"lightgray"}
                color2="tomato"
              />
            </div>
          ))}
        </div>
      </Loader>
      <FormTextArea
        label="Comment"
        rows={5}
        value={reviewObj.review}
        onChange={(e) =>
          setReviewObj((prev) => ({ ...prev, review: e.target.value }))
        }
        placeholder="Please provide us your valuable feedback"
        fieldClass={styles.textarea}
      />
      <FormButton
        type="submit"
        text="Submit"
        disabled={props.isLoading}
        showLoader={props.isLoading}
      />
    </form>
  );
};

export default ReviewPostForm;
