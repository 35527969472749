import React, { Fragment, useState } from 'react';
import Divider from '../Divider';
import ReactHTMLParser from 'react-html-parser';

import './style.scss';

type Props = { description: string, propertyMaster: any }

function About(props: Props) {

    const MAX_SIZE = 450;
    const [showReadMore, setShowReadMore] = useState(false);

    let description = props.description || '';
    let howToReach = props.propertyMaster.how_to_reach || '';
    let guestAccess = props.propertyMaster.guest_access || "";
    let otherThingsToNote = props.propertyMaster.other_things_to_note || '';


    const handleClick = () => setShowReadMore(!showReadMore)

    const renderDescriptions = () => (
        <Fragment>
            {
                (guestAccess) &&
                <div className="mb-4 mt-3">
                    <h6><strong>Guest Access</strong></h6>
                    <p className="descriptions-content">{ReactHTMLParser(guestAccess)}</p>
                </div>
            }
            {
                (otherThingsToNote) &&
                <div className="mb-4">
                    <h6><strong>Other thing to Note</strong></h6>
                    <p className="descriptions-content">{ReactHTMLParser(otherThingsToNote)}</p>
                </div>
            }
            {
                (howToReach) &&
                <div className="mb-4">
                    <h6><strong>How to reach</strong></h6>
                    <p className="descriptions-content">{ReactHTMLParser(howToReach)}</p>
                </div>
            }
        </Fragment>
    )

    if (description.length > MAX_SIZE) {

        if (showReadMore) description = props.description;
        else description = props.description.substring(0, 400);

    }

    return (
        !props.description ? <div /> :
            <Fragment>
                <Divider />
                <div className="about-container">

                    <h4><strong>About</strong></h4>
                    <p className="description">{ReactHTMLParser(description)}{!showReadMore && '...'}</p>

                    {showReadMore && renderDescriptions()}
                    <span onClick={handleClick} className="read-more">
                        {showReadMore ? 'Read Less' : 'Read More'}
                    </span>
                </div>

            </Fragment>
    )
}

export default About;
