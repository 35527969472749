import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';

import '../styles/date-field.scss';
import { updateSearchParams } from 'store/actions';

type Props = {
    activeOption: number,
}

function CheckInDate(props: Props) {

    const dispatch = useDispatch();
    const { search }: any = useSelector(state => state);

    const active = props.activeOption === 2;

    const monthNames = ["Jan", "Feb", "Mar", "Apl", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    let dateString = null;

    if (search.checkInDate) {
        
        /* old logic start */
        // let month = new Date(search.checkInDate).getMonth();
        // let day = new Date(search.checkInDate).getDate();
        // dateString = monthNames[month] + " " + day;
        /* old logic end */

        /* new logic start */
        var dateArray = search.checkInDate.split("-");
        let month = dateArray[0] - 1;
        let day = dateArray[1];
        dateString = monthNames[month] + " " + day;
    }

    const handleClear = () => {
        dispatch(updateSearchParams({
            checkInDate: null,
        }))
    }

    return (
        <div className={`checkdate-field-container ${active && 'active'} ${props.activeOption !== 0 && 'reduce-date-width'}`}>

            <div className="text-input-container">
                <div className="checkdate-heading">Check In</div>
                <div className="checkdate-subhead">{dateString ? dateString : 'Add dates'}</div>
            </div>

            <div className={`cross-icon ${(active && dateString) && 'visible'}`} onClick={handleClear}>
                <IoMdClose />
            </div>

        </div>
    )
}

export default CheckInDate;
