import React from 'react';

import './style.scss';

type Props = {
    label: string,
    type?: string;
    placeholder?: string;
    className?: string;
    name?: string;
    value?: string;
    defaultValue?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    maxLength?: number;
    required?: boolean,
    min?: number;
    onBlur?: () => void,
    onClick?: () => void,
    max?: number;
    disabled?: boolean,
    pattern?: string,
    title?: string,
    style?: React.CSSProperties;
    minLength?: number;
}

FormField.defaultProps = {
    required: true,
    disabled: false
}

export function FormField(props: Props) {

    return (
        <div className="form-field" onClick={props.onClick} onBlur={props.onBlur}>
            <div className="form-field-label">{props.label}</div>
            <input
                type={props.type}
                className={`form-field-input ${props.className}`}
                name={props.name}
                autoComplete={"off"}
                defaultValue={props.defaultValue}
                placeholder={props.placeholder}
                value={props.value}
                disabled={props.disabled}
                required={props.required}
                onChange={props.onChange}
                maxLength={props.maxLength}
                minLength={props.minLength}
                min={props.min}
                max={props.max}
                pattern={props.pattern}
                title={props.title}
                style={props.style} />
        </div>
    )
}


