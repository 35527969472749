import styles from "./style.module.scss";

const ProgressBar = ({
  progressPercentage = 0,
  showText = false,
  showTitle = false,
  progressText = "",
  height = 4,
  backgroundColor = "",
  foregroundColor = "",
  borderRadius = 2,
  inputClass = "",
}) => {
  return (
    <div
      className={styles.backgroundBar}
      style={{ height, backgroundColor, borderRadius }}
      title={showTitle ? `${progressPercentage}% ${progressText}` : ""}
    >
      <div
        className={styles.progressBar}
        style={{
          width: `${progressPercentage}%`,
          height,
          borderRadius,
        }}
      >
        <div
          className={`${styles.animatedProgressBar} ${inputClass}`}
          style={{ background: foregroundColor, borderRadius }}
        >
          {showText && progressPercentage >= 20 && (
            <span>
              {progressPercentage}% {progressText}
            </span>
          )}
        </div>
      </div>
      {showText && progressPercentage < 20 && (
        <span className={styles.percentageText}>
          {progressPercentage}% {progressText}
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
