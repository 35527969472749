import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import { AppDispatch } from "helpers/GenericType";
import { getPropertyReviewList } from "../../store/actions/ReviewActions";
import AllReviews from "./allReviews";
import Rating from "./rating";
import ReviewCard from "./reviewCard/reviewCard";
import styles from "./style.module.scss";

type Props = {
  flatId: string;
  setReviews: (count?: number, avgRating?: number) => void;
};

const Reviews = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { hotelId }: any = useParams();
  const isMobile = useCheckMobileDevice();
  const [reviewData, setReviewData] = useState({
    totalReviews: 0,
    overallRating: 0,
    reviews: [],
    ratings: [],
  });
  const [showMore, setShowMore] = useState(false);
  const [defaultReviewId, setDefaultReviewId] = useState(0);
  const maxReviewsLimit = isMobile ? 3 : 4;
  const { reviews, ratings } = reviewData;

  const getReviews = (hotelId: string, flatId: string) => {
    dispatch(getPropertyReviewList({ propertyId: hotelId, flatId }))
      .then((res: any) => {
        setReviewData(res);
        props.setReviews(res.totalReviews, res.overallRating);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const reviewWrapper = useMemo(
    () => (
      <div className={styles.headingWrapper}>
        <span>★</span>
        <span>{reviewData.overallRating || 0}</span>
        <span>·</span>
        <span>{reviewData.totalReviews || 0} reviews</span>
      </div>
    ),
    [reviewData.overallRating, reviewData.totalReviews]
  );

  const handleViewMore = (reviewId: number) => {
    setShowMore(true);
    if (reviewId) {
      setDefaultReviewId(reviewId);
    } else {
      setDefaultReviewId(0);
    }
  };

  const closeAllReview = () => {
    setShowMore(false);
    setDefaultReviewId(0);
  }

  useEffect(() => {
    if (hotelId && props.flatId) {
      getReviews(hotelId, props.flatId);
    }
  }, [hotelId, props.flatId]);

  return (
    <div className={styles.ratingWrapper} id='review-section'>
      {reviewWrapper}
      {!isMobile && <Rating ratings={ratings} inOneRow={false} />}
      <div className={styles.scrollContainer}>
        <div className={styles.reviewCardWrapper}>
          {reviews.slice(0, maxReviewsLimit).map((review, index) => (
            <div className={styles.reviewCard} key={index}>
              <ReviewCard review={review} handleShowMore={handleViewMore} />
            </div>
          ))}
        </div>
      </div>
      {reviews.length > maxReviewsLimit && (
        <button
          className={styles.showMoreBtn}
          onClick={() => {
            handleViewMore(0);
          }}
        >
          Show all {reviews.length} reviews
        </button>
      )}
      {showMore && (
        <AllReviews
          showMore={showMore}
          handleClose={closeAllReview}
          reviews={reviews}
          ratings={ratings}
          reviewWrapper={reviewWrapper}
          defaultReview={Number(defaultReviewId)}
        />
      )}
    </div>
  );
};

export default Reviews;
