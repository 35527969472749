import axios from "axios";
import Strings from "utilities/Strings";
import { Notification } from "shared-components/Notification";

/*
 * Api Interceptor
 * Handles Server Error,
 * Handles status code 1 request
 */
export const apiInterceptor = () =>
  axios.interceptors.response.use(
    (response) => {
      if (response.data?.responseData?.responseMsg) {
        Notification("", response.data?.responseData?.responseMsg, "success");
      }

      if (
        response.data?.responseStatus?.statusCode === "1" ||
        response.data?.responseStatus?.statusCode >= 400
      ) {
        Notification(
          response.data?.responseData?.errorType,
          response.data?.responseData?.message,
          "warning"
        );
      }
      return response;
    },
    (error) => {
      if (
        error.response?.data?.responseStatus?.statusCode === "1" ||
        error.response?.data?.responseStatus?.statusCode >= 400
      ) {
        Notification(
          error.response?.data?.responseStatus?.errorType ||
            Strings.SYSTEM_ERROR,
          error.response?.data?.responseStatus?.message ||
            Strings.SOMETHING_WENT_WRONG,
          "warning"
        );
      } else {
        Notification(
          Strings.SYSTEM_ERROR,
          Strings.SOMETHING_WENT_WRONG,
          "warning"
        );
      }
      throw error;
    }
  );
