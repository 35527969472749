import { nodeActionDispatcher, fileActionDispatcher } from "store/actionDispatcher";
import API from "api-handler/nodeApiType";

export const UserActionType = {
  GET_PROFILE_DATA: "GET_PROFIEL_DATA",
  UPDATE_PROFILE_DATA: "UPDATE_PROFILE_DATA",
  USER_UPDATE: 'USER_UPDATE',
  LOGIN_VIA_GOOGLE: 'LOGIN_VIA_GOOGLE',
};

export const updateProfile = (params: object) => (dispatch: any) => {
  return fileActionDispatcher(
    params,
    UserActionType.LOGIN_VIA_GOOGLE,
    API.updateProfileData,
    dispatch,
    "put"
  );
};

export const getProfileData = (params: object) => (dispatch: any) => {
  return nodeActionDispatcher(
    params,
    UserActionType.GET_PROFILE_DATA,
    API.getProfileData,
    dispatch,
    "get"
  );
};
