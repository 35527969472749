import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import UserImg from "../../../../assets/user-demo.png";

type Props = {
    details: any,
    propertyMaster: any,
}

function HostDetails(props: Props) {
    return (
        <div className="host-details-container mt-4">
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h4>{props.propertyMaster.sub_property_type} in {props.propertyMaster.property_type} hosted by {props.details.name}</h4>
                    <h5 className="f-16 mb-0">{props.propertyMaster.sub_property_config_details}</h5>
                </div>
                <a ><img className="image" src={UserImg} /></a>
            </div>
        </div>
    )
}

export default HostDetails;
