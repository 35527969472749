import React, { Fragment } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { updateSearchParams } from "store/actions";
import { navigateTo, Navigation } from "helpers/Navigation";

type Props = { data: any };

function PropertyList(props: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search }: any = useSelector((state) => state);

  const handleClick = (id: number, flat_id: number, name: string) => {
    const queryParams = {
      ...search,
      locationName: name,
    };
    dispatch(updateSearchParams({ ...queryParams, locationId: id }));
    navigateTo(
      Navigation.hdp,
      history,
      { location_id: id },
      {
        ...queryParams,
        flat_id: flat_id,
      },
      true
    );
  };

  const handleViewMore = (id: any = "") => {
    navigateTo(
      Navigation.hlp,
      history,
      { locationId: 0 },
      {
        ...search,
        headId: id,
      }
    );
  };

  const propertyTypes = props.data.propertyTypes;

  return (
    <Fragment>
      {propertyTypes.map((parentItem: any, index: number) => {
        let showViewMore = false;
        let tempParentItemList = [...parentItem.list];

        if (tempParentItemList.length > 5) tempParentItemList.length = 5;

        if (parentItem.list.length > 5) showViewMore = true;

        return (
          <div className="property-list-container" key={index}>
            <h2 className="heading">{parentItem.heading}</h2>
            <p className="text-center pl-2 pr-2" style={{ fontSize: "14px" }}>
              {parentItem.subtitle}
            </p>
            <div className="container">
              <div className="circle" />
            </div>
            <div className="list-container pb-3">
              {tempParentItemList.map((item: any) => (
                <div
                  key={item.id}
                  className="property-list-card"
                  onClick={() => handleClick(item.id, item.flat_id, item.name)}
                >
                  <img
                    src={item.image}
                    alt={item.name}
                    className="property-list-image"
                  />
                  <div className="property-list-name">{item.name}</div>
                </div>
              ))}

              {showViewMore && (
                <div
                  key={100}
                  className="property-list-card-2"
                  onClick={() => handleViewMore(parentItem.headId)}
                >
                  <div className="property-list-image card-image-overlay">
                    <div className="view-more">
                      Explore <br />
                      More
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </Fragment>
  );
}

export default React.memo(PropertyList);
