import { useState } from "react";
import Divider from "../Divider";
import "./style.scss";
import { Modal } from "shared-components";
import { FaMendeley } from "react-icons/fa";
import { BsHouseDoor } from "react-icons/bs";
import { HiOutlineDocumentRemove } from "react-icons/hi";

type Props = {
  policies: any;
  amenities: any;
};

function HightlightSection(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const houseRules = props.policies.HouseRules || [];
  const amenities = props.amenities.filter(
    (el: any) => el.is_highlighted != "1"
  );
  const filteredHouseRules = houseRules.filter(
    (el: any) => el.is_highlighted == "1"
  );
  const cancellationPolicy = props.policies.cancelation || [];

  amenities.length = 4;

  return (
    <>
      <Divider />
      <div className="hightlight-section">
        {amenities.length !== 0 && (
          <div className="d-flex mt-3">
            <FaMendeley size={30} className="mr-4" />
            <div>
              <div className="title">Highlights</div>
              <div className="amenities-list">
                {amenities.map((el: any, index: number) => (
                  <div className="amenitie" key={index}>
                    <img className="image" src={el.icon} alt="" />
                    <div className="image-text">{el.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {filteredHouseRules.map((el: any) => (
          <div className="d-flex mt-3">
            <BsHouseDoor size={30} className="mr-4" />
            <div>
              <div className="title">{el.policy_name}</div>
              <p className="desc">{el.highlighted_description}</p>
            </div>
          </div>
        ))}

        <div className="d-flex mt-3">
          <HiOutlineDocumentRemove size={30} className="mr-4" />
          <div>
            <div className="title">Cancellation Policy</div>
            <p className="desc">
              {cancellationPolicy[0] && cancellationPolicy[0].policy_name}
              <span
                className="ml-2 color-primary font-weight-bold cursor-pointer"
                onClick={() => setShowModal(true)}
              >
                Read More
              </span>
            </p>
          </div>
        </div>

        <Modal
          visible={showModal}
          heading="Cancellation Policy"
          handleClose={() => setShowModal(false)}
        >
          <div className="cancellation-modal">
            {cancellationPolicy.map((el: any, index: number) => (
              <div key={index} className="canc-container d-flex mt-3 align-items-center">
                <HiOutlineDocumentRemove size={30} className="mr-3" />
                <div>
                  <div className="title">{el.policy_name}</div>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default HightlightSection;
