import React, { useState } from "react";
import HeaderSection from "./components/HeaderSection";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import MobileSearchContainer from "components/MobileSearch/MobileSearchContainer";

type Props = {
  hideSearchOnInitialRender?: boolean;
  activeNavbar: boolean;
};

function HeaderSectionContainer(props: Props) {
  const isMobile = useCheckMobileDevice();
  const [activeNavbar, setActiveNavbar] = useState(props.activeNavbar);

  const changeBackground = () => {
    if (window.scrollY > 20) setActiveNavbar(true);
    else setActiveNavbar(false);
  };

  if (!props.hideSearchOnInitialRender) {
    window.addEventListener("scroll", changeBackground);
  }

  return (
    <div>
      {isMobile ? (
        <MobileSearchContainer />
      ) : (
        <HeaderSection activeNavbar={activeNavbar} />
      )}
    </div>
  );
}

HeaderSectionContainer.defaultProps = {
  hideSearchOnInitialRender: true,
};

export default React.memo(HeaderSectionContainer);
