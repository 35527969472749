import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "helpers/GenericType";
import HotelListingPage from "./HotelListingPage";
import { navigateTo, Navigation } from "helpers/Navigation";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import { getHotelList, updateSearchParams } from "store/actions";
import { getQueryParamsFromUrl } from "utilities/HelperFunctions";

function HotelListingPageContainer() {
  const history = useHistory();
  const isMobile = useCheckMobileDevice();
  const { locationId }: any = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { search, hotel }: any = useSelector((state) => state);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const queryParams = getQueryParamsFromUrl();
    if (queryParams) {
      // Update Store Values
      const params = {
        locationId: locationId || "",
        locationName: queryParams.locationName || "",
        checkInDate: queryParams.checkInDate || "",
        checkOutDate: queryParams.checkOutDate || "",
        adult: queryParams.adult || 0,
        children: queryParams.children || 0,
        infant: queryParams.infant || 0,
      };
      dispatch(updateSearchParams(params));

      // Api Call
      const apiParams = {
        location_id: queryParams.headId ? null : locationId,
        check_in: queryParams.checkInDate,
        check_out: queryParams.checkOutDate,
        of_adults: queryParams.adult,
        of_children: queryParams.children,
        of_infants: queryParams.infant,
        head_id: queryParams.headId,
      };
      setShowLoader(true);
      dispatch(getHotelList(apiParams)).finally(() => setShowLoader(false));
    }
  }, []);

  const handleHotelClick = (hotelId: string) => {
    navigateTo(Navigation.hdp, history, { hotelId: hotelId }, search, true);
  };

  const hotelList = hotel.hotelList;

  return (
    <HotelListingPage
      isMobile={isMobile}
      hotelList={hotelList}
      showLoader={showLoader}
      locationName={search.locationName}
      handleHotelClick={handleHotelClick}
    />
  );
}

export default HotelListingPageContainer;
