import "./styles/signup-style.scss";
import {
  DateField,
  FormButton,
  FormField,
  Modal,
  GenderDropdown,
  PictureUpload,
} from "shared-components";

type Props = {
  error: string;
  isHost?: boolean;
  showLoader: boolean;
  handleClose: () => void;
  handleSubmit: (params: any) => void;
  setImageFile: (params: any) => void;
};

function Signup(props: Props) {
  return (
    <Modal heading="Sign up" visible={true} handleClose={props.handleClose}>
      <form className="signup-container" onSubmit={props.handleSubmit}>
        <PictureUpload updateImage={props.setImageFile} />

        <FormField
          name="name"
          placeholder="Please enter your name"
          label="Name"
        />

        <div className="description">
          Make sure it matches the name on your government ID.
        </div>

        {!props.isHost && (
          <>
            <DateField
              name="dob"
              placeholder="Date of birth"
              lable="Date of Birth"
              max={new Date().toISOString().split("T")[0]}
            />

            <GenderDropdown name="gender" label="Gender" />
            <div className="description">
              To sign up, you need to be at least 18. Your birthday won’t be
              shared with other people.
            </div>
          </>
        )}

        {props.isHost && (
          <FormField
            name="password"
            placeholder="Please enter your password"
            label="Password"
            type="password"
          />
        )}

        <FormField
          name="email"
          placeholder="Please enter your email"
          label="Email"
        />

        <div className="description">
          We'll email you trip confirmations and receipts.
        </div>

        <div className="description">
          I agree to "BlaBlaStay" Terms of Service, Payments Terms of Service,
          Privacy Policy, and Nondiscrimination Policy.
        </div>

        {props.error !== "" && (
          <div style={{ color: "red", fontSize: "14px" }}>{props.error}</div>
        )}

        <div className="dropdown-divider" />

        <FormButton text="Submit" type="submit" showLoader={props.showLoader} />
      </form>
    </Modal>
  );
}

export default Signup;
