import { useEffect, useRef, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import userImg from "../../../assets/user-demo.png";
import styles from "./style.module.scss";

type Props = {
  review: any;
  hideBox?: boolean;
  autoHeight?: boolean;
  handleShowMore: (param: number) => void
};

const ReviewCard = (props: Props) => {
  const commentSpanRef = useRef<HTMLDivElement | null>(null);
  const commentWrapperRef = useRef<HTMLDivElement | null>(null);
  const { review, hideBox, autoHeight, handleShowMore } = props;
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (commentSpanRef.current && commentWrapperRef.current && review.review) {
      if (
        commentSpanRef.current.scrollHeight >
        commentWrapperRef.current.clientHeight
      ) {
        setShowMore(true);
      }
    }
  }, [commentSpanRef, commentWrapperRef, review.review]);

  return (
    <div className={`${styles.cardWrapper} ${hideBox ? styles.noBox : ""}`}>
      <div className={styles.reviewerWrapper}>
        <img
          className={styles.img}
          src={review.guestImage || userImg}
          alt={review.guestName}
        />
        <div className={styles.reviewer}>
          <b>{review.guestName}</b>
          <br />
          <span className={styles.date}>{review.createdOn}</span>
        </div>
      </div>

      <div
        ref={commentWrapperRef}
        className={`${styles.comment} ${autoHeight ? styles.autoHeight : ""}`}
      >
        <div ref={commentSpanRef}>
          {review.review}
        </div>
      </div>
      {showMore && (
        <span className={styles.showMore} onClick={() => handleShowMore(review.id)}>
          Show More <FaAngleRight />
        </span>
      )}
    </div>
  );
};

export default ReviewCard;
