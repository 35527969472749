import React, { Fragment, useState } from "react";
import "./style.scss";
import { TiTick } from "react-icons/ti";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Divider from "../Divider";

const arrangements = [
  {
    title: "Sleeping",
    list: [
      { id: 1, title: "Bedroom 1", description: "1 double bed", icon: "" },
      { id: 1, title: "Bedroom 2", description: "1 double bed", icon: "" },
      {
        id: 1,
        title: "Common spaces",
        description: "1 sofa bed, 1 sofa",
        icon: "",
      },
    ],
  },
];

// , {
//     title: 'Kitchen & Food',
//     list: [
//         { id: 1, title: 'Kitchen', description: "Pirvate & Shared", icon: "" },
//         { id: 1, title: 'Food', description: "Order from outside", icon: "" },
//         { id: 1, title: 'Food', description: "In house Resotement(Paid)", icon: "" },
//     ]
// }, {
//     title: 'BroadBand',
//     list: [
//         { id: 1, title: 'Jio - yes', description: "Good Speed on internet", icon: "" },
//         { id: 1, title: 'Airtel - yes', description: "Sometime work", icon: "" },
//     ]
// }

const arrangementTabs = ["Sleeping", "Kitchen & Food", "Activity", "Broadband"];

type Props = {
  activities: [];
  broadband: [];
  kitchenFood: [];
  sleepingArrangements: [];
};

function Arrangments(props: Props) {
  const [activeTab, setActiveTab] = useState(0);

  const renderTabs = () => (
    <div className="reviews-tabs-style">
      <div className="reviews-tabs-heading-row">
        {arrangementTabs.map((title: any, index: number) => (
          <div
            key={index}
            className={`col-auto heading-container p-0 ${activeTab === index && "active"
              }`}
          >
            <div className="header-name" onClick={() => setActiveTab(index)}>
              {title}
            </div>
            <div className={`${activeTab === index && "header-border"}`}></div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderSleepingArrangements = () => (
    <div className="sleeping-list">
      {props.sleepingArrangements.map((el: any, index: number) => (
        <div className="sleeping-type-card m-2" key={index}>
          <img src={el.icon} className="icon" alt="" />
          <div className="name">{el.title}</div>
          <div className="type">{el.description}</div>
        </div>
      ))}
    </div>
  );

  const renderKitchen = () => {
    if (props.kitchenFood.length != 0) {
      return props.kitchenFood.map((el: any, index: number) => (
        <div className="container-list" key={index}>
          <div>
            <span>Private or Shared</span> Properties has {el.private_or_shared}{" "}
            kitchen
          </div>
          <div>
            <span>Breakfast</span>{" "}
            {el.breakfast == 0
              ? `Included ₹${el.breakfast_price}` || 0
              : "Not included"}
          </div>
          <div>
            <span>Lunch</span>{" "}
            {el.lunch == 0 ? `₹${el.lunch_price || 0}` : "Not included"}
          </div>
          <div>
            <span>Dinner</span>{" "}
            {el.dinner == 0 ? `₹${el.dinner_price || 0}` : "Not included"}
          </div>
          <div>
            <span>In house restatement</span>{" "}
            {el.in_house_restatement == "Yes" ? "Available" : "Not available"}
          </div>
          <div>
            <span>Order from outside</span>{" "}
            {el.order_from_outside == "Yes" ? "Available" : "Not available"}
          </div>
        </div>
      ));
    }
  };

  const renderBroadband = () => {
    if (props.broadband.length != 0) {
      return props.broadband.map((el: any, index: number) => (
        <div className="container-list" key={index}>
          <div>
            <span className="title">Provider</span> {el.provider}
          </div>
          <div>
            <span className="title">Speed</span> {el.speed}
          </div>
          <div>
            <span className="title">Mobile Networks</span> {el.mobile_networks}
          </div>
        </div>
      ));
    }
  };

  const renderActivities = () => {
    return (
      <div className="container-list2">
        {" "}
        {props.activities.map((activity: any, index: number) => (
          <div key={index}>
            <span className="title">{activity.activity}</span>
            <span style={activity.paidorfree == 0 ? { color: "green" } : {}}>
              {activity.paidorfree == 1
                ? `Paid - ₹${activity.price || 0}`
                : "Free"}
            </span>
          </div>
        ))}
      </div>
    );
  };

  let isEmpty = false;

  if (
    props.sleepingArrangements.length === 0 &&
    props.activities.length === 0 &&
    props.broadband.length === 0 &&
    props.kitchenFood.length === 0
  ) {
    isEmpty = true;
  }

  return isEmpty ? (
    <div />
  ) : (
    <Fragment>
      <Divider />
      <div className="sleeping-arrangement-container">
        <h4>
          <strong>Arrangments</strong>
        </h4>
        {renderTabs()}
        <div className="py-4">
          {activeTab == 0 && renderSleepingArrangements()}
          {activeTab == 1 && renderKitchen()}
          {activeTab == 2 && renderActivities()}
          {activeTab == 3 && renderBroadband()}
        </div>
      </div>
    </Fragment>
  );
}

export default Arrangments;
