import { nodeActionDispatcher } from 'store/actionDispatcher';
import API from 'api-handler/nodeApiType';

export const ReviewActionType = {
	GET_PROPERTY_REVIEW_TYPE_LIST: 'GET_PROPERTY_REVIEW_TYPE_LIST'
}

export const getPropertyReviewList = (params: object) => (dispatch: any) => {
	return nodeActionDispatcher(
		params,
		"",
		API.getPropertyReviews,
		dispatch,
		'get'
	);
};

export const getPropertyReviewTypeList = (params: object) => (dispatch: any) => {
	return nodeActionDispatcher(
		params,
		ReviewActionType.GET_PROPERTY_REVIEW_TYPE_LIST,
		API.getPropertyReviewTypeList,
		dispatch,
		'get'
	);
};

export const checkPropertyReview = (params: object) => (dispatch: any) => {
	return nodeActionDispatcher(
		params,
		"",
		API.checkPropertyReview,
		dispatch,
		'get'
	);
};

export const getBookingReviewByCode = (params: object) => (dispatch: any) => {
	return nodeActionDispatcher(
		params,
		"",
		API.getBookingReviewByCode,
		dispatch,
		'get'
	);
};

export const postPropertyReview = (params: object) => (dispatch: any) => {
	return nodeActionDispatcher(
		params,
		"",
		API.postPropertyReview,
		dispatch,
		'post'
	);
};