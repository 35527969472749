import React from 'react';
import '../styles/date-field.scss';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchParams } from '../../../store/actions/SearchAction';

type Props = {
    activeOption: number,
}

function CheckOutDate(props: Props) {

    const dispatch = useDispatch();
    const { search }: any = useSelector(state => state);

    const active = props.activeOption === 3;

    const monthNames = ["Jan", "Feb", "Mar", "Apl", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    let dateString = null;

    if (search.checkOutDate) {
        /* old logic start */
        // let month = new Date(search.checkOutDate).getMonth();
        // let day = new Date(search.checkOutDate).getDate();
        // dateString = monthNames[month] + " " + day;
        /* old logic end */

        /* new logic start */
        var dateArray = search.checkOutDate.split("-");
        let month = dateArray[0] - 1;
        let day = dateArray[1];
        dateString = monthNames[month] + " " + day;
    }

    const handleClear = () => {
        dispatch(updateSearchParams({
            checkOutDate: null,
        }))
    }

    return (
        <div className={`checkdate-field-container ${active && 'active'} ${props.activeOption !== 0 && 'reduce-date-width'}`}>

            <div className="text-input-container">
                <div className="checkdate-heading">Check Out</div>
                <div className="checkdate-subhead">{dateString ? dateString : 'Add dates'}</div>
            </div>

            <div className={`cross-icon ${(active && dateString) && 'visible'}`} onClick={handleClear}>
                <IoMdClose />
            </div>

        </div>

    )
}

export default CheckOutDate;
