import React from 'react';
import styles from './style.module.scss';

type Props = {
  label: string,
  placeholder?: string;
  className?: string;
  fieldClass?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean,
  onBlur?: () => void,
  onClick?: () => void,
  disabled?: boolean,
  title?: string,
  rows?: number,
  cols?: number,
  style?: React.CSSProperties;
}

FormTextArea.defaultProps = {
  required: true,
  disabled: false
}

export function FormTextArea(props: Props) {

  return (
    <div className={`${styles.formField} ${props.fieldClass}`} onClick={props.onClick}>
      <div className={styles.formFieldLabel}>{props.label}</div>
      <textarea
        className={`${styles.formFieldInput} ${props.className}`}
        name={props.name}
        autoComplete={"off"}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        value={props.value}
        disabled={props.disabled}
        required={props.required}
        onChange={props.onChange}
        onBlur={props.onBlur}
        rows={props.rows}
        cols={props.cols}
        title={props.title}
        style={props.style}
      ></textarea>
    </div>
  )
}
