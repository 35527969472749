import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HomePage from './HomePage';
import homeData from 'jsonData/homePage.json';
import { getHomePageData } from 'store/actions';
import { AppDispatch } from 'helpers/GenericType';
import useCheckMobileDevice from 'helpers/useCheckMobileDevice';

function HomePageContainer() {

	const isMobile = useCheckMobileDevice();
	const dispatch: AppDispatch = useDispatch();
	const { master }: any = useSelector(state => state);
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		setShowLoader(true);
		dispatch(getHomePageData({}))
			.finally(() => setShowLoader(false))
	}, []);

	let homePageData = homeData;

	if (Object.keys(master).length !== 0) {
		homePageData = master;
	}

	return (
		<HomePage isMobile={isMobile}
			showLoader={showLoader}
			homePageData={homePageData} />
	)
}

export default HomePageContainer;