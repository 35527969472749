import React from 'react';
import './style.scss';
import { FaRupeeSign, FaFacebookF } from 'react-icons/fa';
import { GrLanguage, GrTwitter, GrInstagram } from 'react-icons/gr';

function Footer() {
    return (
        <div className="container-fluid">
            <div className="row footer">

                <div className="col footer-left"> © 2023 BlaBlaStay,  All rights reserved by Hostaying Services LLP
                    ·<span>Privacy</span>
                    ·<span>Terms</span>
                    ·<span>Sitemap</span>
                </div>

                <div className="col-auto footer-right p-0">
                    <GrLanguage size={15} /> <span className="mr-4">English(IN)</span>
                    <FaRupeeSign size={12} /> <span className="mr-4">INR</span>

                    <span><FaFacebookF size={18} className="ml-4 mr-4" /></span>
                    <span><GrTwitter size={18} className="mr-4" /></span>
                    <span><GrInstagram size={18} /></span>
                </div>

            </div>
        </div>
    )
}

export default Footer
