import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'helpers/GenericType';
import { checkPropertyReview } from '../../../store/actions/ReviewActions';
import ReviewPost from 'components/reviews/post';
import { FormButton, Modal } from 'shared-components';
import styles from '../styles/booking-review.module.scss';

type Props = {
  bookingId: number,
};

const BookingReview = (props: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { user }: any = useSelector(state => state);

  const [showReview, setShowReview] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(true);

  const checkReviews = (bookingId: number, showMsg = true) => {
    dispatch(checkPropertyReview({ bookingId, userId: user.user.id }))
      .then((res: any) => {
        setIsSubmitted(res.exist);
        if (res.exist && showMsg) {
          toast.error("You have already shared your feedback with us!");
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (props.bookingId) {
      checkReviews(props.bookingId, false);
    }
  }, [props.bookingId]);

  return (
    isSubmitted ? null :
      <>
        <FormButton
          text="Share your Feedback"
          type="button"
          onClick={() => setShowReview(prev => !prev)}
          className={styles.reviewBtn}
        />
        {showReview &&
          <Modal
            heading={'Review and Feedback'}
            visible={showReview}
            handleClose={() => setShowReview(false)}
            className={styles.reviewModal}
          >
            <ReviewPost
              bookingId={props.bookingId}
              setIsSubmitted={setIsSubmitted}
            />
          </Modal>
        }
      </>
  )
}

export default BookingReview;
