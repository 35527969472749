import React from "react";

import "./style.scss";

type Options = {
  listValue: string;
  listText: string;
};

type Props = {
  name: string;
  value?: string;
  label: string;
  options: Options[];
  onChange?: (e:any) => void,
};

export function FormSelect(props: Props) {
  return (
    <div className="form-select">
      <div className="form-select-label">{props.label}</div>
      <select className="form-select-dropdown" name={props.name} onChange={props.onChange}>
        {props.options.map((option, index) => (
          <option onClick={() => {
            if (props.onChange) {
              props.onChange(option.listValue);
            }
          }} key={index} selected={option.listValue === props.value} value={option.listValue}>{option.listText}</option>
        ))}
      </select>
    </div>
  );
}
