import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";

import "../styles/guests-field.scss";
import GuestsForm from "./GuestsForm";
import { updateSearchParams } from "store/actions";

type Props = {
  activeOption: number;
  handleSearch: () => void;
};

function GuestsField(props: Props) {
  const dispatch = useDispatch();
  const { search }: any = useSelector((state) => state);

  let isEmpty = [search.adult, search.children, search.infant].every(
    (el) => el === 0
  );

  const active = props.activeOption === 4;

  const totalGuests = (Number(search.adult) || 0) + (Number(search.children) || 0);
  let guestsString = totalGuests !== 0 ? totalGuests + " guests" : "";
  let infantString = search.infant !== 0 ? search.infant + " infant" : "";
  let text = "";

  if (guestsString) {
    text = guestsString + (infantString ? ", " + infantString : "");
  } else {
    text = guestsString + infantString;
  }

  const handleClear = () => {
    dispatch(
      updateSearchParams({
        adult: 0,
        infant: 0,
        children: 0,
      })
    );
  };

  return (
    <>
      {active && <GuestsForm />}
      <div className={`guests-field-container ${active && "active"}`}>
        <div className="text-input-container">
          <div className="guests-heading">Guests</div>
          <div className="guests-subhead">{!isEmpty ? text : "Add guests"}</div>
        </div>

        <div
          className={`cross-icon ${active && "visible"} ${props.activeOption !== 0 && "reduce-width"
            }`}
          onClick={handleClear}
        >
          <IoMdClose />
        </div>
      </div>
    </>
  );
}

export default GuestsField;
