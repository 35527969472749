import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import styles from "./style.module.scss";
import AuthContainer from "./Auth/AuthContainer";
import { navigateTo, Navigation } from "helpers/Navigation";
import { changeActiveOption } from "store/actions";

type Props = { activeNavbar: boolean };

function UserDropdown(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user }: any = useSelector((state) => state);

  const [activeOption, setActiveOption] = useState(user.activeOption);

  useEffect(() => {
    console.log("after update",user)
    setActiveOption(user.activeOption);
  }, [user]);

  const handleClose = () => dispatch(changeActiveOption(0));

  const handleClick = (id: number) => dispatch(changeActiveOption(id));

  const handleLogout = () => {
    localStorage.clear();
    history.go(0);
  };

  const handleBookings = () => navigateTo(Navigation.myBookings, history);

  const handleNavigate = (id: number) =>
    navigateTo(Navigation.footerLinkPage, history, { id: id });

  const handleProfile = () =>
    navigateTo(Navigation.profile, history, null, {
      prevUrl: encodeURIComponent(
        `${history.location.pathname}${history.location.search}`
      ),
    });

  return (
    <>
      <div className={styles["userdropdown-style"]}>
        {user.user && user.user.image ? (
          <img src={user.user.image} alt="user" className={styles.userImg} />
        ) : (
          <FaUserCircle
            size={33}
            color={props.activeNavbar ? "grey" : "white"}
          />
        )}

        <div className={styles["user-transparent-container"]}>
          <div className={styles["user-dropdown-container"]}>
            {user.user ? (
              <ul className={styles["user-dropdown-list"]}>
                <li>Hi {user.user.name}</li>
                <li onClick={handleBookings}>Bookings</li>
                <div className="dropdown-divider"></div>
                <li onClick={handleProfile}>Profile</li>
                <li onClick={() => handleLogout()}>Logout</li>
                <li onClick={() => handleClick(0)}>Help</li>
              </ul>
            ) : (
              <ul className={styles["user-dropdown-list"]}>
                <li onClick={() => handleClick(1)}>Log In</li>
                <li onClick={() => handleClick(2)}>Sign Up</li>
                <div className="dropdown-divider"></div>
                {/* <li onClick={() => handleClick(0)}>Host your home</li> */}
                <li onClick={() => handleClick(0)}>Host an experience</li>
                <li onClick={() => handleNavigate(9)}>Help</li>
              </ul>
            )}
          </div>
        </div>
      </div>

      {(activeOption === 1 || activeOption === 2) && (
        <AuthContainer
          activeOption={activeOption}
          isHost={false}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default UserDropdown;
