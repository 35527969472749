import { BiSearch } from "react-icons/bi";
import {
  FaRegUserCircle,
  FaRegCalendarAlt,
  FaSignOutAlt,
} from "react-icons/fa";

import styles from "./style.module.scss";
import AuthContainer from "components/auth-section/Auth/AuthContainer";

type Props = {
  user: any;
  showMenu: boolean;
  handleClick: (id: number) => void;
  currentSelection: number;
  activeOption?: number
};

function BottomNavigationMenu(props: Props) {

  return (
    <div
      className={`${styles["bottom-navigation-menu"]} ${!props.showMenu && "invisible"
        }`}
    >
      <div className={styles["bottom-navigations"]}>
        <div
          className={`${styles["navigation-item"]} ${props.currentSelection === 1 ? styles.active : ""
            }`}
          onClick={() => props.handleClick(1)}
        >
          <BiSearch size={30} className={styles.icon} />
          <div className={styles.item}>Explore</div>
        </div>

        {/* <div
          className={styles["navigation-item"]}
          onClick={() => props.handleClick(1)}
        >
          <AiOutlineHeart size={30} className="icon" />
          <div className="item">Saved</div>
        </div> */}

        <div
          className={`${styles["navigation-item"]} ${[2, 3].includes(props.currentSelection) ? styles.active : ""
            }`}
          onClick={() => props.handleClick(props.user ? 2 : 3)}
        >
          {props.user ? (
            <>
              <FaRegCalendarAlt size={30} className={styles.icon} />
              <div className={styles.item}>Bookings</div>
            </>
          ) : (
            <>
              <FaRegUserCircle size={30} className={styles.icon} />
              <div className={styles.item}>Log In</div>
            </>
          )}
        </div>
        {props.user ? (
          <div
            className={`${styles["navigation-item"]} ${props.currentSelection === 4 ? styles.active : ""
              }`}
            onClick={() => props.handleClick(4)}
          >
            {props.user.image ? (
              <img src={props.user.image} alt="user" className={styles.userImg} />
            ) : (
              <FaRegUserCircle size={30} className={styles.icon} />
            )}
            <div className={styles.item}>Profile</div>
          </div>
        ) : (
          ""
        )}

        {props.user ? (
          <div
            className={`${styles["navigation-item"]} ${props.currentSelection === 4 ? styles.active : ""
              }`}
            onClick={() => props.handleClick(5)}
          >
            <FaSignOutAlt size={30} className={styles.icon} />
            <div className={styles.item}>Logout</div>
          </div>
        ) : (
          ""
        )}

        {(props?.activeOption === 1 || props.currentSelection === 3) && (
          <AuthContainer
            activeOption={1}
            isHost={false}
            handleClose={() => props.handleClick(0)}
          />
        )}
      </div>
    </div>
  );
}

export default BottomNavigationMenu;
